import {createElement as h} from "react";
import {cs} from "../../common/react/chain-services";
import {loadRouting} from "../loaders/load-routing";
import {authRoutes} from "./auth-routes";
import {consumeContext, provideContext} from "../../common/react/context";
import {createBasicApis} from "../../apis/auth/basic-apis";
import {createCommonApis} from "../../apis/common/common-apis";
import {EStaticsLayout} from "../../layout/estatics-layout";
import { Load2 } from "../../common/react/load2";

export const loadAuthRoutes = ({intendedRoute}) => cs(
    consumeContext("auth"),
    ["routing", ({auth}, next) => loadRouting({
        routes: authRoutes,
        getDefaultRoute: () => {
            if (intendedRoute.value) {
                // intendedRoute.onChange(null);
                return intendedRoute.value;
            }

            return "/extensions";
        },
        next,
    })],
    ({auth}, next) => provideContext("apis", {...createBasicApis({auth: auth.auth, onUnauthenticated: () => auth.invalidate()}), ...createCommonApis()}, next),
    // (_, next) => loadSubscription({next}),
    ({routing, apiDocs}) => {
        return EStaticsLayout({
            children: h(routing.route.component, {
                setIntendedRoute: intendedRoute.onChange,
            })
        });
    },
);

// const loadSubscription = ({next}) => cs(
//     consumeContext("apis"),
//     ["subscription", ({apis}, next) => Load2({
//         fetch: apis.subscription.getSubscriptions,
//         next
//     })],
//     ({subscription}) => {
//         // return console.log(subscription);
//         return next();
//     }
// );
