import {Button, Col, Image, Input, Row, Spin, Tabs} from "antd";
import {cs} from "../../../common/react/chain-services";
import {consumeContext} from "../../../common/react/context";
import {UseState} from "../../../common/react/use-state";
import classnames from 'classnames';
import "./shop-analyzer.less";
import {Overview} from "./tabs/overview/overview";
import {Analytics} from "./tabs/analytics/analytics";
import {About} from "./tabs/about/about";
import { Invoke } from "../../../common/react/invoke";
import {load} from "dotenv";
import {LoadingOutlined} from "@ant-design/icons";

const {TabPane} = Tabs;

const SHOP_ANALYZER_TABS = {
    OVERVIEW: {
        key: "1",
        title: "Overview",
        render: (data) => (
            <Overview
                data={data.value}
            />
        )
    },
    ANALYTICS: {
        key: "2",
        title: "Analytics",
        render: (data) => (
            <Analytics
                data={data.value}
            />
        )
    },
    ABOUT: {
        key: "3",
        title: "About",
        render: (data) => (
            <About
                data={data.value}
            />
        )
    },
};

export const ShopAnalyzer = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["data", (_, next) => UseState({initValue: null, next})],
        ["loading", (_, next) => UseState({initValue: false, next})],
        ({routing, apis, data, loading}, next) => Invoke({
            onMounted: async() => {
                if(routing.params.shop_id) {
                    loading.onChange(true);
                    const result = await apis.shop.analyzeShop(routing.params.shop_id);
                    loading.onChange(false);
                    data.onChange(result);
                }
            },
            next
        }),
        ({apis, data, loading}) => {
            return (
                <div className="shop-analyzer-route">
                    <Row justify="center">
                        <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
                            <div className="header">Shop Analyzer</div>
                            <div className="content">
                                <Input.Search
                                    placeholder="Search by Etsy shop name or url"
                                    enterButton="Search"
                                    loading={loading.value}
                                    onSearch={async (value) => {
                                        loading.onChange(true);
                                        const result = await apis.shop.analyzeShop(value);
                                        loading.onChange(false);
                                        data.onChange(result);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    {loading.value && <Row justify="center">
                        {<Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />}
                    </Row>}

                    {data.value && (
                        <>
                            <div className="shop-basic-info-wrapper">
                                <Row justify="center">
                                    <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
                                        <div className="shop-basic-info">
                                            <div className="shop-info">
                                                <div className="shop-image">
                                                    <Image
                                                        width={64}
                                                        preview={false}
                                                        src={data.value.shop_info.icon_url_fullxfull}
                                                    />
                                                </div>
                                                <div className="shop-name">
                                                    <div className="title">ETSY SHOP ANALYZER</div>
                                                    <div className="name">{data.value.shop_info.shop_name}</div>
                                                </div>
                                            </div>
                                            <Button type="primary"
                                                    href={`https://www.etsy.com/shop/${data.value.shop_info.shop_name}`}
                                                    size="large">
                                                View on Etsy
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {/*<Tabs*/}
                            {/*    renderTabBar={(props) => {*/}
                            {/*        const {activeKey, onTabClick} = props;*/}
                            {/*        return (*/}
                            {/*            <div className="custom-tab-bar">*/}
                            {/*                <Row justify='center'>*/}
                            {/*                    <Col sm={24} md={22} lg={20} xl={18} xxl={16}>*/}
                            {/*                        {Object.values(SHOP_ANALYZER_TABS).map(tab => (*/}
                            {/*                            <div onClick={() => onTabClick(tab.key)}*/}
                            {/*                                 key={tab.key}*/}
                            {/*                                 className={classnames('tab-navigator', {active: activeKey === tab.key})}*/}
                            {/*                            >*/}
                            {/*                                {tab.title}*/}
                            {/*                            </div>*/}
                            {/*                        ))}*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            </div>*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*    defaultActiveKey={SHOP_ANALYZER_TABS.OVERVIEW.key}*/}
                            {/*>*/}
                            {/*    <TabPane key={SHOP_ANALYZER_TABS.OVERVIEW.key}>*/}
                            {/*        {SHOP_ANALYZER_TABS.OVERVIEW.render(data)}*/}
                            {/*    </TabPane>*/}
                            {/*    <TabPane key={SHOP_ANALYZER_TABS.ANALYTICS.key}>*/}
                            {/*        {SHOP_ANALYZER_TABS.ANALYTICS.render(data)}*/}
                            {/*    </TabPane>*/}
                            {/*    <TabPane key={SHOP_ANALYZER_TABS.ABOUT.key}>*/}
                            {/*        {SHOP_ANALYZER_TABS.ABOUT.render(data)}*/}
                            {/*    </TabPane>*/}
                            {/*</Tabs>*/}
                            {SHOP_ANALYZER_TABS.OVERVIEW.render(data)}
                            {SHOP_ANALYZER_TABS.ANALYTICS.render(data)}
                        </>
                    )}
                </div>
            );
        }
    );
