export const getBestSellingProductsOptions = ({listings}) => {
    const _listings = listings.sort((a,b) => b.est_total_sales - a.est_total_sales).slice(0, 40);

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // Use axis to trigger tooltip
                type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
            },
            extraCssText: 'max-width: 300px; white-space: break-spaces;'
        },
        grid: {
            top: 20,
        },
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: _listings.map(i => i.title),
            axisLabel: {
                ellipsis: "...",
                overflow: "truncate",
                rotate: 60,
                width: 70
            },
        },
        series: [
            {
                name: 'Total Sale',
                type: 'bar',
                stack: 'total_sale',
                emphasis: {
                    focus: 'series'
                },
                data: _listings.map(i => i.est_total_sales),
                barMaxWidth: 80,
            },
        ]
    }
}

export const getCategoriesOptions = ({shop_categories, listings}) => {
    const sorted_categories = shop_categories.sort((a,b) => b.total_sales - a.total_sales);

    let c0 = sorted_categories.filter(c => c.depth == 0).map(t => ({
        ...t,
        value: t.total_sales
    }));

    let c1 = sorted_categories.filter(c => c.depth == 1).map(t => ({
        ...t,
        value: t.total_sales
    }));

    const series = [c0, c1]

    return {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: series.map(function (data, idx) {
            const top = idx * 50;
            return {
                name: `Category Depth ${idx + 1}`,
                type: 'pie',
                radius: [20, 80],
                left: top + '%',
                width: '50%',
                top: 'center',
                height: 400,
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1
                },
                data: data
            }
        })
    }
}

