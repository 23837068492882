import React from "react";
import {cs} from "../common/react/chain-services";
import {UseState} from "../common/react/use-state";
import {fragments} from "../common/react/fragments";
import {Watch} from "../common/react/watch";
import {Invoke} from "../common/react/invoke";
import {waitTimeout} from "../common/utils/wait-timeout";
import {fetchUser} from "../apis/user/fetch-user";
import {spc} from "../common/react/state-path-change";

export const Authentication = ({next}) => cs(
    ["state", (_, next) => UseState({
        getInitValue: () => ({
            user: null,
            auth: getLsData("auth"),
            verifiedAuthToken: null,
        }),
        next,
    })],
    ({state}, next) => fragments(
        next(),

        Watch({
            value: state.value.auth,
            onChanged: (auth) => setLsData("auth", auth)
        }),

        !state.value.verifiedAuthToken && Invoke({
            fn: async () => {
                console.log('invoke');
                state.onChange({
                    verifiedAuthToken: true,
                    ...await (async () => {
                        const { auth } = state.value;

                        if(!auth) return {};
                        else {
                            try {
                                const {user, sessions, subscription} = await confirmUser(auth);

                                return { user: {...user, sessions, subscription}, auth }
                            } catch (e) {

                            }
                        }
                    })()
                })
            }
        })
    ),
    ({state}, next) => (
        state.value.error ? (
            "Network error, cannot connect to Verb server!"
        ) : !state.value.verifiedAuthToken ? (
            "Loading..."
        ) : (
            next()
        )
    ),
    ({state}) => next({
        user: state.value.user,
        auth: state.value.auth,
        invalidate: () => spc(state, ["auth"], () => null),
        logout: () => {
            state.change((s) => ({
                ...s,
                auth: null,
                user: null
            }));
        },
        setAuth: ({auth, user}) => {
            state.change(s => ({
                ...s,
                auth,
                user
            }))
        }
    })
);

const confirmUser = async (auth) => {
    return await Promise.race([
        (async () => {
            const res = await fetchUser(auth);

            if (res.status !== 401) {
                return await res.json();
            } else {
                return null;
            }
        })(),
        (async () => {
            await waitTimeout(5000);
            return null;
        })()
    ]);
};

const getLsData = (key) => {
    if (!localStorage) return;

    const str = localStorage.getItem(key);
    return str && JSON.parse(str);
};

const setLsData = (key, item) => {
    if (!localStorage) return;

    if (item == null) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, JSON.stringify(item));
    }
};
