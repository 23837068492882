import {createElement as h} from "react";
import {cs} from "../../common/react/chain-services";
import {loadRouting} from "../loaders/load-routing";
import {guestRoutes} from "./guest-routes";
import {consumeContext, provideContext} from "../../common/react/context";
import {createGuestApis} from "../../apis/guest/guest-apis";
import {UseState} from "../../common/react/use-state";
import {createCommonApis} from "../../apis/common/common-apis";

export const loadGuestRoutes = ({intendedRoute}) => cs(
    consumeContext("auth"),
    ["apiDocs", (_, next) => UseState({next})],
    ["routing", ({auth}, next) => loadRouting({
        routes: guestRoutes,
        getDefaultRoute: () => {
            const intended = (() => {
                // if (auth.rejectCurrentAuthRoute.value) {
                //     return null;
                // }
                return window.location.hash?.substring(1);
            })();
            return "/login" + (!intended || intended === "/extensions" ? "" : "?intendedRoute=" + encodeURIComponent(intended) );
        },
        next,
    })],
    ({}, next) => provideContext("apis", {...createGuestApis(), ...createCommonApis()}, next),
    ({routing, apiDocs}) => h(routing.route.component, {
        setIntendedRoute: intendedRoute.onChange,
        apiDocs
    }),
);
