const credentials = {};

const host = process.env.REACT_APP_API;

export const createFetcher = ({useProxy, headers, onUnauthenticated}) => {
    const urlPath = (url) => host + url;

    const requestHeaders = (changeHeaders) => ({
        "content-type": "application/json",
        ...changeHeaders ? changeHeaders(headers) : headers,
        ...useProxy && {
            "x-proxy-target-host": host,
        },
    });

    const raw = async ({url, body, ...params}, {changeHeaders}={}) => {
        return await fetch(urlPath(url), {
            ...params,
            ...credentials,
            headers: requestHeaders(changeHeaders),
            body: body == null ? undefined : JSON.stringify(body),
        });
    };

    const rawMultipart = async ({url, body, ...params}, {changeHeaders}={}) => {
        return await fetch(urlPath(url), {
            ...params,
            ...credentials,
            headers: {
                ...changeHeaders ? changeHeaders(headers) : headers,
                ...useProxy && {
                    "x-proxy-target-host": host,
                }
            },
            body,
        });
    };

    const readHttpResponse = async (res, {params}) => {
        const getBlobResponse = async () => {
            const contentType = res.headers.get("content-type");
            const contentDisposition = res.headers.get("content-disposition");
            const accepted =
                contentType?.startsWith("application/vnd") ||
                contentType?.startsWith("application/json") ||
                contentType?.startsWith("text/csv");

            // contentDisposition?.startsWith("attachment")
            if (accepted) {
                const filename = contentDisposition?.match(/filename=(.+);/)[1]?.replace(/"/g, "");
                const blob = await res.blob();
                return {blob, filename};
            }

            return {};
        };

        const getJsonResponse = async () => {
            const contentType = res.headers.get("content-type");
            if (contentType?.startsWith("application/json")) {
                try {
                    return await res.json();
                } catch (err) {
                    return res;
                }
            } else {
                return null;
            }
        };

        if (res.status === 401) {
            if (onUnauthenticated) {
                return onUnauthenticated({reInvoke: ({}) => fetch1(params, {})});
            }

            throw await getJsonResponse();
        } else if (res.status >= 400) {
            throw await getJsonResponse();
        } else if (res.url.includes("download")) {
            return await getBlobResponse();
        } else {
            return await getJsonResponse();
        }
    };

    const fetch1 = async (params, {changeHeaders}={}) => {
        const res = await raw(params, {changeHeaders});


        return await readHttpResponse(res, {params});
    };

    const fetchMultipart = async (params, {changeHeaders}={}) => {
        const res = await rawMultipart(params, {changeHeaders});

        return await readHttpResponse(res, {params});
    };

    return {
        getRaw: (url) => raw({url}),
        head: (url) => fetch1({
            url,
            method: "HEAD",
        }),
        get: (url) => fetch1({url}),
        post: (url, body=null, changeHeaders = null) => fetch1({
            url,
            method: "POST",
            body,
        }, {changeHeaders}),
        postMultipart: (url, body = null) => fetchMultipart({
            url,
            method: "POST",
            body,
        }, ),
        put: (url, body=null) => fetch1({
            url,
            method: "PUT",
            body,
        }),
        delete: (url) => fetch1({
            url,
            method: "DELETE",
        }),
    };
};
