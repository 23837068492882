import {cs} from "../../../../../common/react/chain-services";
import {Col, Row, Card} from "antd";

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {BarChart, PieChart} from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import {getBestSellingProductsOptions, getCategoriesOptions} from "./options";
import {shopHistoriesOptions} from "../../../follow-shop/shop-histories-options";

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, PieChart, CanvasRenderer, LegendComponent]
);

export const Analytics = ({data}) => cs(
    () => {
        return (
            <div className="shop-analyzer-overview" style={{marginTop: 10}}>
                <Row justify="center" gutter={16}>
                    {/*<Col sm={24} md={22} lg={20} xl={18} xxl={16} style={{marginBottom: 20}}>*/}
                    {/*    <Card title="Best selling products" bordered={false}>*/}
                    {/*        <ReactEChartsCore*/}
                    {/*            echarts={echarts}*/}
                    {/*            option={shopHistoriesOptions(data)}*/}
                    {/*            style={{height: '300px', width: '100%'}}*/}
                    {/*            notMerge={true}*/}
                    {/*            lazyUpdate={true}*/}
                    {/*            theme={"theme_name"}*/}
                    {/*        />*/}
                    {/*    </Card>*/}
                    {/*</Col>*/}
                    <Col sm={24} md={22} lg={20} xl={18} xxl={16} style={{marginBottom: 20}}>
                        <Card title="Best selling products" bordered={false}>
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getBestSellingProductsOptions(data)}
                                style={{height: '300px', width: '100%'}}
                                notMerge={true}
                                lazyUpdate={true}
                                theme={"theme_name"}
                            />
                        </Card>
                    </Col>
                    <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
                        <Card title="Top Categories" bordered={false}>
                            <ReactEChartsCore
                                echarts={echarts}
                                option={getCategoriesOptions(data)}
                                style={{height: '200px', width: '100%'}}
                                notMerge={true}
                                lazyUpdate={true}
                                theme={"theme_name"}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
)
