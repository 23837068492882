import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Alert, Button, Form, Input} from "antd";
import "./login-route.less";
import {consumeContext} from "../../../common/react/context";
import {fetchUser} from "../../../apis/user/fetch-user";
import {UseState} from "../../../common/react/use-state";

const layout = {
    layout: 'vertical'
};

export const LoginRoute = () => cs(
    consumeContext("apis"),
    consumeContext("auth"),
    consumeContext("routing"),
    ["error", (_, next) => UseState({next})],
    ({apis, auth, routing, error}) => {
        const onFinish = async (values) => {
            try {
                const auth1 = await apis.login(values);
                if(auth1.success) {
                    const {user, sessions, subscription} = await (await fetchUser(auth1)).json();

                    auth.setAuth({
                        auth: auth1,
                        user: {
                            ...user,
                            sessions,
                            subscription
                        }
                    })
                }
            } catch (e) {
                error.onChange(e)
            }

        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <div className="login-route-9jk">
                <div className="logo">
                    <img width="50px" src="https://res.cloudinary.com/dxlqxbssm/image/upload/v1622607921/S-logo-2_olw4kg.svg" alt=""/>
                    <div className="text">Estatics</div>
                </div>
                <div className="content">
                    <div className="text">Get Started.</div>

                    {error.value?.message && <>
                        <Alert message={error.value?.message} type="error" showIcon />
                        <br/>
                    </>}


                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="label">Email</div>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Email"/>
                        </Form.Item>

                        <div className="label">Password</div>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password size="large" placeholder="Password"/>
                        </Form.Item>

                        <Form.Item >
                            <Button size="large" type="primary" htmlType="submit" block>
                                Sign in
                            </Button>
                        </Form.Item>
                        <div style={{textAlign: 'center'}}>Dont have your account? <a onClick={() => routing.goto("register")}>Sign up now!</a></div>
                    </Form>
                </div>
            </div>
        );
    }
);
