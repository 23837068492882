import {cs} from "../../common/react/chain-services";
import {Invoke} from "../../common/react/invoke";
import {UseState} from "../../common/react/use-state";
import {arrMapToO, keepOnly} from "../../common/utils/objects";
import {chain} from "../../common/utils/fs";
import {buildUrlQuery} from "../../common/utils/build-url-query";
import {Router} from "./router";
import {keyed} from "../../common/react/keyed";
import {parseUrlQuery} from "../utils/parse-url-query";

export const Routing = ({routes, defaultParams, getDefaultRoute, next}) => cs(
    ["router", ({}, next) => Router({routes, next})],

    ({router}, next) => router.current != null ? next() : (() => {

        const defaultRoute = getDefaultRoute();
        return cs(
            keyed(defaultRoute),
            () => Invoke({
                fn: () => {
                    setTimeout(
                        () => router.pushHistory(defaultRoute),
                        0
                    )
                },
            }),
        );
    })(),

    ({router, router: {routes, current: {props, route}}, block}) => next((() => {
        const queryParams = parseUrlQuery(props.location.search);

        const params = {...defaultParams, ...props.match.params, ...queryParams};

        const toPath = (name, params2) => {
            const nextRoute = routes.find((r) => r.name === name);

            const params1 = {
                ...defaultParams,
                ...props.match.params,
                ...route.query && keepOnly(queryParams, route.query.filter(({carryOn}) => carryOn).map(({paramName}) => paramName)),
                ...params2,
            };


            const query = nextRoute.query && chain(
                nextRoute.query.filter(({paramName}) => params1[paramName] != null),
                (_) => arrMapToO(_, ({paramName}) => params1[paramName], ({paramName}) => paramName),
                (_) => buildUrlQuery(_),
            );
            return nextRoute.path.replace(/:(\w+)/g, (s) => encodeURIComponent(params1[s.substring(1)])) + (query??"");
        };

        return {
            params,
            pathName: route.name,
            route: route.route,
            goto: async (name, params) => {
                return router.pushHistory(toPath(name, params));
            },
            pushParams: (params) => router.pushHistory(toPath(route.name, params)),
            updateParams: (params1) => router.pushHistory(toPath(route.name, {...params, ...params1})),
            pushHistory: router.pushHistory,
            toPath,
        };
    })())
);
