const {createElement: h, Fragment} = require("react");

export const keyed = (key) => (_, next) => (
    keyed1({key, next})
);

export const keyed1 = ({key, next}) => (
    h(Fragment, {key},
        next()
    )
);
