import React from "react";
import {cs} from "../../../common/react/chain-services";
import "./extensions-route.less";
import {Load2} from "../../../common/react/load2";
import {consumeContext} from "../../../common/react/context";
import {Alert, Modal, Tag, Typography} from "antd";
import {keyed} from "../../../common/react/keyed";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const { Paragraph } = Typography;

export const ExtensionsRoute = ({}) => cs(
    consumeContext("apis"),
    consumeContext("auth"),
    ["license", ({apis}, next) => {
        return Load2({
            key: "license",
            fetch: () => apis.users.getLicense(),
            next
        });
    }],
    ({license, auth, apis}) => {
        return (
            <div className="extensions-route-9ew">
                {/*{license.value?.status === "pending" && (*/}
                {/*    <Alert message="Please contact us to activate your license extension" */}
                {/*           type="warning" */}
                {/*           showIcon */}
                {/*           closable*/}
                {/*           banner*/}
                {/*    />*/}
                {/*)}*/}

                <div className="header">Extensions</div>

                <div className="box">
                    <div className="text-lg">Estatics extensions</div>
                    <div className="desc">
                        It is easier to reach all hidden information of a listing on Etsy such as views, favorites,
                        tags, taxonomies.
                        <br/>
                        Get started by downloading the extension and adding it to your browser.
                    </div>

                    <div className="downloading">
                        <div
                            onClick={() => window.open("https://chrome.google.com/webstore/detail/estatics/momgcmdnlbopbcmglainncpclaopfkje?hl=en-US", "_blank")}
                        >
                            <div>Chrome version</div>
                            <img width="60px"
                                 src="https://res.cloudinary.com/dxlqxbssm/image/upload/v1622642558/Google_Chrome-Logo.wine_fcf9sc.png"
                                 alt=""/>
                        </div>
                        <div
                            onClick={() => window.open("https://addons.mozilla.org/en-US/firefox/addon/estatics/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search", "_blank")}>
                            <div>Firefox version</div>
                            <img width="60px"
                                 src="https://res.cloudinary.com/dxlqxbssm/image/upload/v1622642560/Firefox-Logo.wine_iagiis.png"
                                 alt=""/>
                        </div>
                    </div>
                </div>

                {license.value && (
                    <div className="box-2">
                        <div className="license">
                            License:&nbsp; <Paragraph copyable>{license.value?.key}</Paragraph>
                        </div>
                        <div className="active-devices">
                            <div>Active Devices: {license.value.devices?.length} / {auth.user.subscription.num_devices}</div>

                            <div className="devices-list">
                                {license.value.devices.map((device, i) => cs(
                                    keyed(i),
                                    () => <div>
                                        <div>{device.info.ua}</div>
                                        <a onClick={() => {
                                            Modal.confirm({
                                                icon: <ExclamationCircleOutlined />,
                                                content: `Are you really want to revoke a device?`,
                                                onOk: async () => {
                                                    await apis.licenses.revokeDevice(license.value._id, device._id);
                                                    license.reload();
                                                },
                                            });
                                        }}>Revoke</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
