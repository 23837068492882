const React = require("react");

export const Watch = ({onChanged, value, initRun, next}) => React.createElement(Watch1, {onChanged, value, initRun, next});

class Watch1 extends React.Component {

    constructor(props, context) {
        super(props, context);

        if (props.initRun) {
            props.onChanged(this.props.value, undefined);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.props.onChanged(this.props.value, prevProps.value);
        }
    }

    render() {
        const {next} = this.props;

        return next ? next() : null;
    }
}
