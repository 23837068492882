import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Alert, Input, Table} from "antd";
import {consumeContext} from "../../../common/react/context";
import {UseState} from "../../../common/react/use-state";
import {capitalizeFirstLetter} from "../../../common/string-utils";
import {formatMoney} from "../../../common/utils/math-util";

export const WhatIsMyRankTab = ({}) => cs(
    consumeContext("apis"),
    ["info", (_, next) => UseState({next})],
    ["loading", (_, next) => UseState({initValue: false, next})],
    ({apis, info, loading}) => {
        return (
            <div className="what-rank">
                <Input.Search
                    placeholder="Input your shop name" enterButton="Search"
                    loading={loading.value}
                    onSearch={async (value) => {
                        loading.onChange(true);
                        const result = await apis.shop.getShopRank(value);
                        loading.onChange(false);
                        info.onChange({result, keyword: value});
                }}/>
                <br/><br/>
                <div className="info">
                    {info.value && (
                        info.value.result.shop.length === 0 ? <Alert message={<span>Shop <strong>{info.value.keyword}</strong> not found!</span>} type="error" showIcon /> :
                            (() => {
                                const columns = [
                                    {
                                        title: 'Shop Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                        render: (text, item) => <a href={`https://www.etsy.com/shop/${item.name}`} target="_blank">{item.name}</a>
                                    },
                                    {
                                        title: 'Country',
                                        dataIndex: 'country',
                                        key: 'country',
                                    },
                                    {
                                        title: 'Category',
                                        dataIndex: 'category',
                                        key: 'category',
                                    },
                                    {
                                        title: 'Rank',
                                        dataIndex: 'rank',
                                        key: 'rank',
                                    },
                                ];

                                return <Table columns={columns} dataSource={info.value.result.shop} pagination={false}/>
                            })()
                    )}
                </div>
            </div>
        );
    }
);
