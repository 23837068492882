import React from "react";
import {cs} from "../../../../common/react/chain-services";
import {Form, Input, Modal} from "antd";
import {UseState} from "../../../../common/react/use-state";
import {Static2} from "../../../../common/react/static-2";
import { EditOutlined } from "@ant-design/icons";
import "./editable-input.less";

export const EditableInput = ({value, onSave}) => cs(
    ["editing", (_, next) => UseState({initValue: false, next})],
    ["formStatic", (_, next) => Static2({next})],
    ({editing, formStatic}) => {
        const onToggle = () => {
            editing.onChange(!editing.value);
            formStatic.get().setFieldsValue({value: value});
        };

        const onInputSave = async () => {
            try {
                const values = await formStatic.get().validateFields();
                if(values.value !== value) {
                    await onSave(values.value);
                }
                onToggle();
            } catch (e) {
                console.log("Save Failed", e);
            }
        };

        return <Form ref={formStatic.set}>
            {editing.value ? (
                <Form.Item name="value">
                    <Input autoSize={{minRows: 5}} focus onBlur={onInputSave} onPressEnter={onInputSave}/>
                </Form.Item>
            ) : (
                <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: 22}} onClick={onToggle}>
                    {value ? <>{value} <EditOutlined/></> : <span className="placeholder">Click to add note</span>}
                </div>
            )}
        </Form>
    }
);

export const EditableInput2 = ({title, value, onSave}) => cs(
    ["editing", (_, next) => UseState({initValue: false, next})],
    ["formStatic", (_, next) => Static2({next})],
    ({editing, formStatic}) => {
        const onInputSave = async () => {
            try {
                const values = await formStatic.get().validateFields();
                if(values.value !== value) {
                    await onSave(values.value);
                }
                editing.onChange(false)
            } catch (e) {
                console.log("Save Failed", e);
            }
        };

        return <>
            <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: 22}} onClick={() => editing.onChange(true)}>
                {value ? <>{value} <EditOutlined/></> : <span className="placeholder">Click to add note</span>}
            </div>

            <Modal title={title} visible={editing.value} onOk={onInputSave} onCancel={() => editing.onChange(false)}>
                <Form ref={formStatic.set}>
                    <Form.Item name="value">
                        <Input autoSize={{minRows: 5}} defaultValue={value} focus onPressEnter={onInputSave}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }
);
