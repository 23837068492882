import React from "react";
import {AutoComplete, Breadcrumb, Modal, Space, Table, Tag} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, HomeOutlined} from "@ant-design/icons";

import {cs} from "../../../common/react/chain-services";
import {consumeContext} from "../../../common/react/context";
import {Load2} from "../../../common/react/load2";
import {fragments} from "../../../common/react/fragments";
import {Invoke} from "../../../common/react/invoke";

import {AddUser} from "./add-user-modal";
import {UseState} from "../../../common/react/use-state";
import {AddNewSubscription, UpdateSubscription} from "./subscription-modals";
import {SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPES} from "./constants";

export const USER_TYPES = {
    1: "ADMIN",
    2: "MODERATOR",
    4: "NORMAL_USER",

    ADMIN: 1,
    MODERATOR: 2,
    NORMAL_USER: 4
}

export const UsersRoute = ({}) => cs(
    consumeContext("apis"),
    consumeContext("auth"),
    consumeContext("routing"),
    ["users", ({apis}, next) => Load2({
        fetch: async () => apis.users.getUsers(),
        next
    })],
    ({auth}, next) => auth.user ? next() : null,
    ({auth, routing}, next) => (fragments(
        Invoke({
            onMounted: () => {
                if (!auth.user?.is_super_admin && auth.user?.role !== USER_TYPES.ADMIN) {
                    routing.pushHistory('/');
                }
            }
        }),
        next(),
    )),
    ['selectedUser', (_, next) => UseState({next, initValue: null})],
    ({users, apis, selectedUser}) => {
        const deleteUser = async (userId) => await apis.users.deleteUser(userId);

        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: text => <a>{text}</a>,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render: (role, item) => <Tag color={item.is_super_admin ? 'green': ''}>{item.is_super_admin ? `SUPER_ADMIN` : USER_TYPES[item.role]}</Tag>
            },
            {
                title: '',
                key: 'action',
                render: (item) => (
                    <Space size="middle">
                        {!item.is_super_admin && (
                            <a onClick={() => {
                                Modal.confirm({
                                    icon: <ExclamationCircleOutlined />,
                                    content: `Are you sure to delete user ${item.name} ?`,
                                    onOk: () => deleteUser(item._id),
                                });
                            }}><DeleteOutlined />Delete</a>
                        )}
                    </Space>
                )
            }
        ];

        let filteredList = selectedUser.value ? (users.value || []).filter(u => u.id == selectedUser.value.id) : users.value ?? [];
        filteredList = filteredList.filter(u => !u.is_super_admin);

        return (
            <div style={{minHeight: 300}} className="site-layout-background">
                <div className="tools-nav">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">
                            <HomeOutlined/>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item><strong>Users</strong></Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                {AddUser({apis, reload: users.reload})}

                <br/>

                <AutoComplete
                    allowClear
                    style={{ width: 400 }}
                    options={filteredList?.map(u => ({...u, label: u.email, value: u.id})) ?? []}
                    placeholder="Search email"
                    filterOption={(inputValue, option) => option.email.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    value={selectedUser.value?.email}
                    onSelect={(v, item) => selectedUser.onChange(item)}
                    onSearch={() => selectedUser.onChange(null)}
                />

                <br/>

                <br/>
                <Table
                    columns={columns}
                    loading={users.loading}
                    dataSource={filteredList}
                    expandable={{
                        expandedRowRender: user => !user.is_super_admin && (
                            TableSubscriptions({user, subscriptions: user.subscriptions, reload: users.reload})
                        ),
                        rowExpandable: record => !record.is_super_admin
                    }}
                />
            </div>
        );
    }
);

const TableSubscriptions = ({user, subscriptions, reload}) => {
    console.log(subscriptions);
    const columns = [
        {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            render: plan => <p>{plan}</p>,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => <p>{created_at}</p>,
        },
        {
            title: 'Expired At',
            dataIndex: 'expired_at',
            key: 'expired_at',
            render: expired_at => <p>{expired_at}</p>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => <p>{status}</p>,
        },
        {
            title: 'Concurrent/Device',
            key: 'concurrent_devices',
            render: item => <p>{item.num_concurrent_login} / {item.num_devices}</p>,
        },
        {
            title: '',
            key: 'action',
            render: item => {
                if (item.status == SUBSCRIPTION_STATUS.CANCELED && item.plan == SUBSCRIPTION_TYPES.TRIAL) {
                    return null
                }
                return (
                    UpdateSubscription({user, reload, subscription: item})
                )
            },
        },
    ];
    return cs(
        consumeContext('apis'),
        ({apis}) => {
            const isTrialAccount = subscriptions.length == 1;

            return (
                <>
                    {isTrialAccount && AddNewSubscription({user, reload})}
                    <Table
                        rowKey="id"
                        pagination={false}
                        columns={columns}
                        dataSource={subscriptions}
                    />
                </>
            )
        }
    )
}
