import {LoginRoute} from "../../routes/guest-routes/login/login-route";
import {RegisterRoute} from "../../routes/guest-routes/register/register-route";

export const guestRoutes = [
    {
        name: "login",
        path: "/login",
        // query: ["email", "intendedRoute~", "message"],
        route: {
            component: LoginRoute,
        },
    },
    {
        name: "register",
        path: "/register",
        // query: ["inviteToken", "inviteID"],
        route: {
            component: RegisterRoute,
        },
    },
    // {
    //     name: "forgot-password",
    //     path: "/forgot-password",
    //     query: ["email~", "intendedRoute~"],
    //     route: {
    //         component: ForgotPasswordRoute,
    //     },
    // },
    // {
    //     name: "reset-password",
    //     path: "/reset-password",
    //     query: ["email~", "intendedRoute~", "resetToken"],
    //     route: {
    //         component: ResetPasswordRoute,
    //     },
    // },
];

