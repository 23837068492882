const {cache1} = require("../utils/cache1");
const {cs} = require("./chain-services");
const {createContext, createElement: h} = require("react");

const ctxPairs = cache1(() => createContext(null));

export const provideContext = (key, value, next) => (
    h(ctxPairs(key).Provider, {value}, next(value))
);

export const provideContextM = (values, next) => cs(
    ...Object.keys(values).map((key) => (
        (_, next) => h(ctxPairs(key).Provider, {value: values[key]}, next())
    )),
    next,
);

export const consumeContext = (name, as) =>
    [as || name, (_, next) => h(ctxPairs(name).Consumer, {}, next)]
;

export const changeContext = (name, fn, next) => cs(
    consumeContext(name),
    (params) => provideContext(name, fn(params[name]), next),
);
