import React, {createElement as h} from "react";

export const OnUnmounted = ({action, props, next}) => h(OnUnmounted1, {action, props, next});

class OnUnmounted1 extends React.Component {
    componentWillUnmount() {
        this.props.action({
            getLatestProps: () => this.props.props,
        });
    }

    render() {
        const {next} = this.props;
        return next ? next() : null;
    }
}
