import React from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";

import {cs} from "../../../common/react/chain-services";
import {UseState} from "../../../common/react/use-state";
import {Static2} from "../../../common/react/static-2";

const { Option } = Select;

export const AddUser = ({apis, reload}) => cs(
    ["visibleState", (_, next) => UseState({initValue: false, next})],
    ["static1", (_, next) => Static2({next})],
    ({visibleState, static1}) => {
        const onSubmit = () => {
            static1.get().validateFields().then(async (values) => {
                await apis.users.createUsers(values);
                visibleState.onChange(false);
                reload();
            })
        };

        return <>
            <div>
                <Button onClick={() => visibleState.onChange(true)}><UserAddOutlined />Add</Button>
            </div>
            <Modal
                visible={visibleState.value}
                onCancel={() => visibleState.onChange(false)}
                title="Create New User"
                footer={[
                    <Button key="submit" type="primary" onClick={onSubmit}>
                        Create
                    </Button>
                ]}
            >
                <Form ref={static1.set} name="control-ref" layout="vertical">
                    {/*<Form.Item name="name" label="Name" rules={[{ required: true }]}>*/}
                    {/*    <Input />*/}
                    {/*</Form.Item>*/}
                    <Form.Item name="username" label="Username" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true, pattern: "(?=.{8,})", message: "Please type password at least 8 characters" }]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item name="role" label="Roles" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select user roles"
                            allowClear
                        >
                            <Option value={1}>Admin</Option>
                            <Option value={2}>Moderator</Option>
                            <Option value={4}>Normal User</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
);
