import React from "react";
import {cs} from "../common/react/chain-services";
import { HeartOutlined, ShopOutlined, SearchOutlined, ChromeOutlined, CalendarOutlined, StockOutlined, BankOutlined, UserOutlined } from '@ant-design/icons';
import {Avatar, Layout, Menu, Tag, Alert} from "antd";
import "./estatics-layout.less";
import {consumeContext} from "../common/react/context";
import {UseState} from "../common/react/use-state";
import {Watch} from "../common/react/watch";
import PieChartOutlined from "@ant-design/icons/lib/icons/PieChartOutlined";
import RiseOutlined from "@ant-design/icons/lib/icons/RiseOutlined";
import { capitalize } from "lodash";
import * as dayjs from "dayjs";
import { keyed } from "../common/react/keyed";

const { Content, Footer, Sider } = Layout;

export const EStaticsLayout = ({children}) => cs(
    consumeContext("auth"),
    consumeContext("apis"),
    consumeContext("routing"),
    ["selectedKeys", ({routing}, next) => UseState({initValue: [routing.pathName], next})],
    ["collapsed", (_, next) => UseState({initValue: false, next})],
    ({auth, apis, selectedKeys, routing, collapsed}) => {
        const logout = async () => {
            await apis.users.logout();
            auth.logout();
        };

        const isAdmin = auth.user.is_super_admin;
        const isActive = isAdmin ? true : dayjs(auth.user.subscription.expired_at).diff(dayjs(), 'd', true) > 0;

        return (
            <Layout style={{ minHeight: '100vh' }} className={`layout-8ks ${collapsed.value ? `collapsed` : ``}`}>
                {Watch({
                    value: routing.pathName,
                    onChanged: (value) => {
                        if(selectedKeys.value[0] !== value) {
                            selectedKeys.onChange([value]);
                        }
                    }
                })}

                <Sider
                    theme="light"
                    collapsible collapsed={collapsed.value}
                    onCollapse={(v) => collapsed.onChange(v)}
                    // style={{overflow: 'auto', height: '100vh'}}
                >
                    <div className="header-side">
                        <div className="users">
                            <Avatar size={55} style={{backgroundColor: '#294661'}}>U</Avatar>
                            <div className="text">
                                <div className="user">Welcome, <a onClick={() => routing.goto('account')}>{auth.user.name || 'User'}</a> </div>
                                <div className="logout" onClick={() => logout()}>Logout</div>
                            </div>
                        </div>

                        {(() => {
                            const diff = dayjs(auth.user.subscription.expired_at).diff(dayjs(), 'd', true);
                            return <div className="plan">
                                <Tag color="volcano">{`${capitalize(auth.user.subscription.plan)}`}</Tag>
                                <div className="diff">{`${Math.ceil(diff > 0 ? diff : 0)} days left`}</div>
                            </div>
                        })()}


                    </div>
                    <Menu
                        mode="inline" selectedKeys={selectedKeys.value}
                        onClick={(e) => {
                        selectedKeys.onChange(e.keyPath)
                        routing.goto(e.key);
                    }}>
                        {(() => {
                            let list = [
                                {key: "follow-shop", icon: <HeartOutlined />, label: "Follow Shop", disabled: !isActive},
                                {key: "extensions", icon: <ChromeOutlined/>, label: "Extension"},
                                {key: "trending", icon: <RiseOutlined />, label: "Trending", disabled: !isActive},
                                {key: "keyword-research", icon: <PieChartOutlined />, label: "Keyword Research", disabled: !isActive},
                                {key: "product-research", icon: <SearchOutlined/>, label: "Research Product", disabled: !isActive},
                                {key: "shop-analyzer", icon: <StockOutlined />, label: "Shop Analyzer", disabled: !isActive},
                                {key: "calendar", icon: <CalendarOutlined />, label: "Calendar"},
                                {key: "top-shops", icon: <ShopOutlined/>, label: "Top Etsy Shops", disabled: !isActive},
                                {key: "payment", icon: <BankOutlined />, label: "Payment"},
                            ];

                            if (isAdmin) {
                                list = [
                                    {key: "users", icon: <UserOutlined />, label: "Users", disabled: !isActive},
                                ];
                            }

                            return list.map((l, i) => cs(
                                keyed(i),
                                () => <Menu.Item key={l.key} icon={l.icon} disabled={l.disabled}>{l.label}</Menu.Item>
                            ))
                        })()}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    {!isActive && <Alert message={
                        <span>Your subscription is expired. Please contact us or <a href="mailto:support@estatics.co">email us (support@estatics.co)</a> to activate the account</span>
                    } banner style={{fontSize: 15}} />}

                    <Content style={{padding: "20px 20px"}}>
                        {children}
                    </Content>

                    <Footer style={{ fontSize: '8px', color: '#29466199', textAlign: 'center' }}>
                        ©Estatics {new Date().getFullYear()}.&nbsp;
                        The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
                    </Footer>
                </Layout>
            </Layout>
          );
    }
);
