import React from "react";
import {cs} from "../../../common/react/chain-services";
import {consumeContext} from "../../../common/react/context";
import {keyed} from "../../../common/react/keyed";
import {Load2} from "../../../common/react/load2";
import {Table} from "antd";
import {formatMoney} from "../../../common/utils/math-util";
import {capitalizeFirstLetter} from "../../../common/string-utils";

export const topShopsColumns = [
    {
        title: 'Shop Name',
        dataIndex: 'shop_name',
        key: 'shop_name',
        render: (text, item) => <div className="product-row">
            <div className="text"><a href={`https://www.etsy.com/shop/${item.shop_name}`} target="_blank">{item.shop_name}</a></div>
        </div>,
    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (text) => text.split("-").map(c1 => capitalizeFirstLetter(c1)).join(" ")
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
    },
    {
        title: 'Monthly Sales',
        dataIndex: 'monthly_sales',
        key: 'monthly_sales',
        render: (text) => formatMoney(text)
    },
];

export const TopShopsTab = ({}) => cs(
    consumeContext("apis"),
    ["shops", ({apis}, next) => cs(
        () => Load2({
            fetch: async () => {
                return await apis.shop.getTopShops({});
            },
            next
        })
    )],
    ({shops}) => {
        return (
            <div className="table">
                <Table columns={topShopsColumns} dataSource={shops.value || []}/>
            </div>
        );
    }
);
