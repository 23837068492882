import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Button, Form, Modal, Input, Table, Tag, Image} from "antd";
import {consumeContext} from "../../../common/react/context";
import {Static2} from "../../../common/react/static-2";
import {Load2} from "../../../common/react/load2";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {notificationMessage} from "../../../common/notification";
import {formatMoney} from "../../../common/utils/math-util";
import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";
import * as dayjs from "dayjs";
import {DownCircleOutlined, UpCircleOutlined} from "@ant-design/icons";
import {EditableInput, EditableInput2} from "../common/editable-input/editable-input";
import "./follow-shop-route.less";
import {UseState} from "../../../common/react/use-state";
import {shopFavoritesOptions, shopHistoriesOptions} from "./shop-histories-options";
import * as echarts from "echarts/core";
import {getCategoriesOptions} from "../shop-analyzer/tabs/analytics/options";
import ReactEChartsCore from "echarts-for-react/lib/core";
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import {BarChart, PieChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, BarChart, PieChart, CanvasRenderer, LegendComponent]
);

export const FollowShopRoute = ({}) => cs(
    consumeContext("apis"),
    consumeContext("routing"),
    ["formRef", (_, next) => Static2({next})],
    ["loading", (_, next) => UseState({initValue: false, next})],
    ["followedShops", ({apis}, next) => Load2({
        fetch: apis.followShop.getFollowedShops,
        next,
    })],
    ({apis, formRef, followedShops, routing, loading}) => {

        const onRemove = async (id) => {
            await apis.followShop.remove(id);
            followedShops.reload();
        };

        const columns = [
            {
                key: 1,
                title: 'Shop Name',
                dataIndex: 'shop_name',
                render: (text, item) => <a href={`https://etsy.com/shop/${item.shop_name}`} target="_blank">{item.shop_name}</a>,
                ellipsis: true
            },
            {
                key: 9,
                title: 'Note',
                render: (text, item) => EditableInput2(
                    {title: "Note",
                        value: item.note ?? "",
                        onSave: async (value) => {
                            await apis.followShop.update(item._id, {note: value});
                            followedShops.reload();
                        }
                    }),
                ellipsis: true
            },
            {
                key: 2,
                title: 'Favorites',
                dataIndex: 'shop.data.num_favorers',
                // key: 'shop.data.num_favorers',
                render: (text, item) => formatMoney(item.shop.data.num_favorers),
                align: 'center'
            },
            {
                key: 3,
                title: 'Listing',
                render: (text, item) => item.shop.data.listing_active_count,
                align: 'center'
            },
            {
                key: 4,
                title: 'Total Sold',
                render: (text, item) => <Tag color="orange">{formatMoney(item.shop.data.sales)}</Tag>,
                align: 'center'
            },
            // {
            //     key: 5,
            //     title: 'Sold Page',
            //     render: (text, item) => item.shop.data.is_sold_public ? <CheckCircleFilled style={{color: 'green'}} /> : <CloseCircleFilled style={{color: 'red'}}/>,
            //     align: 'center'
            // },
            {
                key: 6,
                title: 'Age',
                render: (text, item) => dayjs(new Date(item.shop.data.creation_tsz * 1000)).fromNow(),
                align: 'center'
            },
            {
                key: 7,
                title: '',
                render: (text, item) => <a onClick={() => routing.goto("shop-analyzer", {shop_id: item.shop_name})}>Report</a>,
                align: 'center'
            },
            {
                key: 8,
                width: '5%',
                render: (text, record) => <a onClick={() => Modal.confirm({
                    title: "Delete item?",
                    content: <span>Do you really want to delete shop <strong>{record.shop_name}</strong>?</span>,
                    onOk: () => onRemove(record._id),
                })}><DeleteOutlined /></a>,
            },
        ];

        const onFinish = async (values) => {
            try {
                await apis.followShop.follow({shop_name: values.shop});
                followedShops.reload();
            } catch (e) {
                notificationMessage({type: "error", description: e.message});
            }
            formRef.get().resetFields();
        };

        return (
            <div className="product-research-route-0sq follow-shop-route-9wq">
                <div className="header">Follow Shop</div>

                <div className="content">
                    <Input.Search
                        placeholder="Input shop name which you want to follow"
                        allowClear
                        enterButton="Follow"
                        loading={loading.value}
                        onSearch={async (value) => {
                            loading.onChange(true);
                            await apis.followShop.follow({shop_name: value});
                            loading.onChange(true);
                            followedShops.reload();
                        }}
                    />


                    {/*<div className="searcher">*/}
                    {/*    <Form*/}
                    {/*        ref={formRef.set}*/}
                    {/*        name="basic"*/}
                    {/*        layout="inline"*/}
                    {/*        onFinish={onFinish}*/}
                    {/*        onFinishFailed={() => {}}*/}
                    {/*    >*/}
                    {/*        <Form.Item*/}
                    {/*            name="shop"*/}
                    {/*            rules={[*/}
                    {/*                {*/}
                    {/*                    required: true,*/}
                    {/*                    message: 'Please input shop name!',*/}
                    {/*                },*/}
                    {/*            ]}*/}
                    {/*        >*/}
                    {/*            <Input placeholder="Shop Name"/>*/}
                    {/*        </Form.Item>*/}

                    {/*        <Form.Item>*/}
                    {/*            <Button type="primary" htmlType="submit">*/}
                    {/*                Follow*/}
                    {/*            </Button>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Form>*/}
                    {/*</div>*/}

                    <div className="table">
                        <Table
                            rowKey={record => record._id}
                            columns={columns}
                            dataSource={followedShops.value || []}
                            loading={followedShops.loading}
                            expandable={{
                                expandedRowRender: record => {
                                    return record.shopHistory[0]?.history && <div className="follow-expanded-row-9qw">
                                        <div className="sales">
                                            <div>
                                                Sales in last 7 days

                                                <ReactEChartsCore
                                                    echarts={echarts}
                                                    option={shopHistoriesOptions(record.shopHistory[0].history, record.shop.data.sales)}
                                                    style={{height: '200px', width: '400px'}}
                                                    notMerge={true}
                                                    lazyUpdate={true}
                                                    theme={"theme_name"}
                                                />
                                            </div>

                                            <div>
                                                Favorites in last 7 days

                                                <ReactEChartsCore
                                                    echarts={echarts}
                                                    option={shopFavoritesOptions(record.shopHistory[0].history, record.shop.data.num_favorers)}
                                                    style={{height: '200px', width: '400px'}}
                                                    notMerge={true}
                                                    lazyUpdate={true}
                                                    theme={"theme_name"}
                                                />
                                            </div>

                                        </div>
                                    </div>;
                                },
                            }}
                        />
                    </div>

                </div>
            </div>
        );
    }
);
