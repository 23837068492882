const {debounce} = require("../utils/debounce");
const {Component, createElement: h} = require("react");

export const UseState = ({next, getInitValue, initValue, debounce = 0}) => h(UseState1, {next, getInitValue, initValue, debounce});

class UseState1 extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: props.getInitValue ? props.getInitValue() : props.initValue,
        };

        const _change = (reduceValue, cb) => {
            this.setState(
                (state) => ({
                    ...state,
                    value: reduceValue(state.value),
                }),
                () => cb && cb(this.state)
            );
        }

        this.change = props.debounce > 0 ? debounce(_change, props.debounce) : _change;

        const _onChange = (value1, cb) => {
            this.setState({value: value1},
                () => cb && cb(this.state))
        };

        this.onChange = props.debounce > 0 ? debounce(_onChange, props.debounce) : _onChange;
    }
    componentWillUnmount() {
        this.unmounted = Date.now();
    }

    setState(state, callback) {
        if (!this.unmounted || Date.now() - this.unmounted > 3000) {
            super.setState(state, callback);
            return;
        }
    }

    render() {
        const {next, debounce} = this.props;
        const {value} = this.state;

        return next({
            value,
            onChange: this.onChange,
            change: this.change,
            isDebounce: !!debounce
        });
    }
}
