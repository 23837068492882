import React from "react";
import {cs} from "../common/react/chain-services";
import {loadAuth} from "../loaders/load-auth";
// import {loadDevice} from "../loaders/load-device";
import {loadAuthRoutes} from "../estatics-web/auth-routes/load-auth-routes";
import {loadGuestRoutes} from "../estatics-web/guest-routes/load-guest-routes";
import {UseState} from "../common/react/use-state";

export const EstaticsRoutes = ({}) => cs(
    ["auth", (_, next) => loadAuth({next})],
    // ({}, next) => loadDevice({next}),
    ["intendedRoute", (_, next) => UseState({next})],
    ({auth, intendedRoute}) => {
        return auth.auth && auth.user ? (
            loadAuthRoutes({intendedRoute})
        ) : (
            loadGuestRoutes({intendedRoute})
        );
    },
);
