import "./overview.less";
import format from "date-fns/format";
import {Button, Col, Drawer, Image, Row, Select, Tooltip} from "antd";

import {cs} from "../../../../../common/react/chain-services";
import {UseState} from "../../../../../common/react/use-state";
import {InfoCircleOutlined} from "@ant-design/icons";
import {SimpleCarousel} from "../../../../../common/react/simple-carousel/simple-carousel";
import {formatMoney} from "../../../../../common/utils/math-util";
import {currencySymbols} from "../../../../../common/string-utils";

const {Option} = Select;

const Carousel = ({items}) =>
    cs(
        ["index", (_, next) => UseState({initValue: 0, next})],
        ["visible", (_, next) => UseState({initValue: false, next})],
        ({index, visible}) => {
            console.log(items);
            const images = items.map((each) => each.image);
            const current = items[index.value];
            
            if(!current) return null;

            return (
                <>
                    <SimpleCarousel
                        images={images}
                        onChange={(i) => index.onChange(i)}
                        waterMark="FEATURED PRODUCTS"
                    />
                    <div className="brief-info">
                        <div className="info">
                            <div className="product-name">{current?.title}</div>
                            <div className="product-price">{currencySymbols[current?.currency_code]}{current?.price}</div>
                        </div>
                        <Button onClick={() => visible.onChange(true)}>Inspect</Button>
                    </div>
                </>
            );
        }
    );

export const Overview = ({data}) => {
    console.log(data);
    return cs(
        ({}) => {
            const stats = [
                {
                    id: "conversion_rate",
                    label: "Conversion rate",
                    value: data.shop_info.conversion_rate + "%",
                },
                {
                    id: "review",
                    label: "Reviews",
                    value: (
                        <>
                            {data.shop_info.reviews}
                            <span className="review-rate">{data.shop_info.rating.toFixed(2)}</span>
                        </>
                    ),
                },
                {
                    id: "listing",
                    label: "Listings",
                    value: data.shop_info.listing_active_count,
                },
                // {
                //   id: "sale_per_listing",
                //   label: "Sales per listing",
                //   value: 92,
                // },
                {
                    id: "admirers",
                    label: "Admirers",
                    value: data.shop_info.num_favorers,
                },
                {
                    id: "esty_since",
                    label: "On Etsy since",
                    value: format(data.shop_info.creation_tsz * 1000, "MMMM dd, yyyy"),
                },
            ];
            return (
                <div className="shop-analyzer-overview" style={{marginTop: 10}}>
                    <Row justify="center">
                        <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
                            <div className="statistics">
                                <div className="statistic-box">
                                    <div className="sb-title">
                                        SALES
                                        <Tooltip title="Number of sales for this shop">
                                            <InfoCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                    <div className="sb-value">{formatMoney(data.shop_info.sales)}</div>
                                </div>
                                <div className="statistic-box">
                                    <div className="sb-title">
                                        REVENUE
                                        <Tooltip title="Estimated revenue for this shop">
                                            <InfoCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                    <div className="sb-value">{formatMoney(data.shop_info.est_revenues)}</div>
                                </div>
                                <div className="statistic-box">
                                    <div className="sb-title">
                                        VIEWS
                                        <Tooltip title="The shop’s total views based on all currently active listings.">
                                            <InfoCircleOutlined/>
                                        </Tooltip>
                                    </div>
                                    <div className="sb-value">{formatMoney(data.shop_info.views)}</div>
                                </div>
                            </div>
                            <div className="overview-center">
                                {(data.listings || []).filter(l => !!l.featured_rank).length > 0 && (
                                    <div className="featured-products part">
                                        <Carousel
                                            items={(data.listings || []).filter(l => !!l.featured_rank)}
                                        />
                                    </div>
                                )}
                                <div className="shop-stats part">
                                    <div className="stats-header">Shop Stats</div>
                                    <div className="stats">
                                        {stats.map((stat) => (
                                            <div className="stat" key={stat.id}>
                                                <div className="stat-name">{stat.label}</div>
                                                <div className="stat-value">{stat.value}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
    )
};
