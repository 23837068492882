import { Typography, Tag } from "antd";
import { cs } from "../../../common/react/chain-services";
import { consumeContext } from "../../../common/react/context";
import { keyed } from "../../../common/react/keyed";
import * as dayjs from "dayjs";
import "./payment-route.less";
import {capitalize} from "lodash";
const { Paragraph } = Typography;

const payments = [
    {type: "Techcombank", name: "NGO ANH TUAN",  account: "19033698228012"},
    {type: "Paypal", account: "muc1nang.tyh@gmail.com"},
    {type: "Payoneer", account: "huyenvly49@gmail.com"},
    {type: "Pingpong", account: "ngoanhtuanhhbg16@gmail.com"}
];

export const PaymentRoute = ({}) => cs(
    consumeContext("auth"),
    ({auth}) => {
        const diff = dayjs(auth.user.subscription.expired_at).diff(dayjs(), 'd', true);

        return <div className="product-research-route-0sq payment-route-9wq">
            <div className="header">Payment</div>

            <div className="content">
                <div className="box">
                    <div className="text-lg">Subscription </div>
                    <div className="plan">
                        <Tag color="volcano">{`${capitalize(auth.user.subscription.plan)} Plan`}</Tag>
                        <div className="diff">{`${Math.ceil(diff > 0 ? diff : 0)} days left`}</div>
                    </div>
                </div>
                <div className="box">
                    <div className="header">Payment Method</div>

                    <div className="payments">
                        {payments.map((payment, i) => cs(
                            keyed(i),
                            () => <div className="payment">
                                <div><strong>{payment.type}</strong></div>
                                {payment.name && <Paragraph copyable>{payment.name}</Paragraph>}
                                <Paragraph copyable>{payment.account}</Paragraph>
                            </div>
                        ))}
                    </div>

                    <div className="how-to-pay">
                        <div className="header">How to purchase?</div>

                        <div className="steps">
                            <div>1. Your payment note: email [Basic / Pro]</div>
                            <br />
                            <div>Example you want to buy basic plan, then you fill in note like this:</div>
                            <br />
                            <div><strong>{auth.user.email} Basic    </strong></div>
                            <br />
                            <div>2. Contact our assistant on the right corner of the screen or <a href="mailto:support@estatics.co">email us (support@estatics.co)</a> </div>
                            <br />
                            <div>3. After 1 hours, The account will be activated.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
)
