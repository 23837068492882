import {OnUnmounted} from "./on-unmounted";
import {Invoke} from "./invoke";
import {fragments} from "./fragments";
import {Static2} from "./static-2";
import {cs} from "./chain-services";

export const GlobalEvent = ({eventName, fn, next}) => cs(
    ["listener", (_, next) => Static2({next})],
    ({listener}) => fragments(
        Invoke({
            props: {fn},
            fn: ({getLatestProps}) => {
                const listener1 = (e) => {
                    const {fn} = getLatestProps();
                    fn(e);
                };
                window.addEventListener(eventName, listener1);
                listener.set(listener1);
            },
        }),
        OnUnmounted({
            action: () => {
                const l1 = listener.get();
                if (l1) {
                    window.removeEventListener(eventName, l1);
                }
            },
        }),
        next?.(),
    ),
);
