import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Badge, Calendar} from "antd";
import "./calendar-route.less";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import {calendarData, calendarData2} from "./calendar-data";
import googleCalendarPlugin from '@fullcalendar/google-calendar';

export const CalendarRoute = ({}) => cs(
    () => {
        return (
            <div className="calendar-route-9wq">
                <div className="header-9ew">Calendar</div>

                <FullCalendar
                    plugins={[ dayGridPlugin, googleCalendarPlugin ]}
                    initialView="dayGridMonth"
                    fixedWeekCount={false}
                    googleCalendarApiKey={"AIzaSyAQcfQLu09A_kiH_gOmmcaSMvAxElcjihs"}
                    events={{googleCalendarId: 'hubspot.com_d41b2nai6vorfggmorp11fum7s@group.calendar.google.com'}}
                />
            </div>
        )
    },
);
