import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Alert, Button, Form, Input} from "antd";
import {consumeContext} from "../../../common/react/context";
import {fetchUser} from "../../../apis/user/fetch-user";
import {omit} from "lodash";
import {UseState} from "../../../common/react/use-state";

export const RegisterRoute = () => cs(
    consumeContext("apis"),
    consumeContext("auth"),
    consumeContext("routing"),
    ["loading", (_, next) => UseState({initValue: false, next})],
    ["errorState", (_, next) => UseState({initValue: {
        error: false,
        message: ''
    }, next})],
    ({apis, auth, loading, errorState, routing}) => {
        const onFinish = async (values) => {
            loading.onChange(true);
            errorState.onChange({error: false, message: ''});
            try {
                const resp = await apis.register(omit(values, ['confirmPassword']));
                if(resp.success) {
                    const resp1 = await apis.login({
                        email: values.email,
                        password: values.password
                    });

                    if(resp1.success) {
                        let {user, sessions, subscription} = await (await fetchUser(resp1)).json();
                        loading.onChange(false);

                        user.sessions = sessions;
                        user.subscription = subscription;

                        auth.setAuth({
                            auth: resp1,
                            user
                        })
                    }
                }
            } catch (e) {
                loading.onChange(false);
                errorState.onChange({error: true, message: e.message});
            }
        };

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <div className="login-route-9jk">
                <div className="logo">
                    <img width="50px" src="https://res.cloudinary.com/dxlqxbssm/image/upload/v1622607921/S-logo-2_olw4kg.svg" alt=""/>
                    <div className="text">Estatics</div>
                </div>
                <div className="content">
                    <div className="text">Welcome.</div>

                    {errorState.value.error && <Alert message={errorState.value.message} type="error" showIcon />}

                    <Form
                        layout="vertical"
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="label">Name</div>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your name!',
                                },
                                { min: 5, message: 'Name must be minimum 5 characters.' },
                            ]}
                        >
                            <Input size="large" placeholder="Name"/>
                        </Form.Item>

                        <div className="label">Email</div>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Email"/>
                        </Form.Item>

                        <div className="label">Password</div>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                { min: 8, message: 'Password must be minimum 5 characters.' },
                            ]}
                        >
                            <Input.Password  size="large" placeholder="Password"/>
                        </Form.Item>

                        <div className="label">Confirm Password</div>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                { min: 8, message: 'Password must be minimum 5 characters.' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password size="large" placeholder="Confirm Password"/>
                        </Form.Item>

                        <Form.Item >
                            <Button size="large" type="primary" htmlType="submit" loading={loading.value} block>
                                Sign up
                            </Button>
                        </Form.Item>

                        <div style={{textAlign: 'center'}}><a onClick={() => routing.goto("login")}>Back to sign in </a></div>
                    </Form>
                </div>
            </div>
        );
    }
);
