import React from "react";
import {cs} from "../../../common/react/chain-services";
import {Select, Tabs} from "antd";
import {consumeContext} from "../../../common/react/context";
import {UseState} from "../../../common/react/use-state";
import {keyed} from "../../../common/react/keyed";
import dayjs from "dayjs";
import {TopCountriesTab} from "./top-countries-tab";
import {TopShopsTab} from "./top-shops-tab";
import {WhatIsMyRankTab} from "./what-is-my-rank-tab";

export const TopShopsRoute = ({}) => cs(
    consumeContext("auth"),
    consumeContext("apis"),
    ({auth}, next) => auth.user ? next() : null,
    ["activeTab", (_, next) => UseState({initValue: "topshop", next})],
    ({activeTab}) => {
        const tabs = [
            {name: "Top Shop", key: 'topshop', render: TopShopsTab},
            {name: "Top Countries", key: 'topcountries', render: TopCountriesTab},
            {name: "What's my rank?", key: 'rank', render: WhatIsMyRankTab},
        ];

        return (
            <div className="product-research-route-0sq">
                <div className="header">
                    Top Etsy Shops
                    <div className="desc">
                        Top Etsy Shops based on sales in the last month. Updated on {dayjs().format('MM/DD/YY')}
                    </div>
                </div>

                <Tabs activeKey={activeTab.value} onChange={(key) => activeTab.onChange(key)}>
                    {tabs.map((tab, i) => cs(
                        keyed(i),
                        () => <Tabs.TabPane tab={tab.name} key={tab.key}>
                            {tab.render({})}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        )
    }
);
