import {sortBy, uniq, uniqBy} from "lodash";
import * as dayjs from "dayjs";

export const shopHistoriesOptions = (histories, current) => {
    let _histories = uniqBy(sortBy(histories, h => -h.timestamp).map((h, i) => ({
        ...h,
        timestamp: dayjs(h.timestamp).format("MM/DD"),
        daily_sales: histories[i + 1] ? histories[i + 1].data.sales - h.data.sales : current - h.data.sales
    })),
        'timestamp',
    );

    if(_histories.length < 7) {
        _histories = [
            ...Array(7 - _histories.length).fill(1).map((_, i) => ({
                daily_sales: 0,
                timestamp: dayjs(_histories[0].timestamp).subtract(7 - _histories.length - i, "day").format("MM/DD")
            })),
            ..._histories
        ];
    }

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // Use axis to trigger tooltip
                type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
            },
            extraCssText: 'max-width: 300px; white-space: break-spaces;'
        },
        grid: {
            top: 20,
        },
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: _histories.map(i => i.timestamp),
            axisLabel: {
                ellipsis: "...",
                overflow: "truncate",
                rotate: 60,
                width: 70
            },
        },
        series: [
            {
                name: 'Total Sale',
                type: 'bar',
                stack: 'total_sale',
                emphasis: {
                    focus: 'series'
                },
                data: _histories.map(i => i.daily_sales),
                barMaxWidth: 80,
            },
        ]
    }
};

export const shopFavoritesOptions = (histories, current) => {
    let _histories = uniqBy(sortBy(histories, h => -h.timestamp).map((h, i) => ({
        ...h,
        timestamp: dayjs(h.timestamp).format("MM/DD"),
        daily_favorites: histories[i + 1] ? histories[i + 1].data.num_favorers - h.data.num_favorers : current - h.data.num_favorers
    })),
        'timestamp',
    );

    if(_histories.length < 7) {
        _histories = [
            ...Array(7 - _histories.length).fill(1).map((_, i) => ({
                daily_favorites: 0,
                timestamp: dayjs(_histories[0].timestamp).subtract(7 - _histories.length - i, "day").format("MM/DD")
            })),
            ..._histories
        ];
    }

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // Use axis to trigger tooltip
                type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
            },
            extraCssText: 'max-width: 300px; white-space: break-spaces;'
        },
        grid: {
            top: 20,
        },
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: _histories.map(i => i.timestamp),
            axisLabel: {
                ellipsis: "...",
                overflow: "truncate",
                rotate: 60,
                width: 70
            },
        },
        series: [
            {
                name: 'Total Sale',
                type: 'bar',
                stack: 'total_sale',
                emphasis: {
                    focus: 'series'
                },
                data: _histories.map(i => i.daily_favorites),
                barMaxWidth: 80,
            },
        ]
    }
};
