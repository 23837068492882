import React from "react";
import {cs} from "../../../common/react/chain-services";
import * as echarts from 'echarts';
import {Static2} from "../../../common/react/static-2";
import worldJson from "./map-world";
import {consumeContext} from "../../../common/react/context";
import {Load2} from "../../../common/react/load2";
import {UseState} from "../../../common/react/use-state";
import {Table} from "antd";
import {topShopsColumns} from "./top-shops-tab";
import {capitalizeFirstLetter} from "../../../common/string-utils";
import sum from 'lodash/sum';
import {keyed} from "../../../common/react/keyed";

export const TopCountriesTab = ({}) => cs(
    consumeContext("apis"),
    ["countriesOverview", ({apis}, next) => cs(
        () => Load2({
            fetch: async () => await apis.shop.getTopCountriesOverview({}),
            next
        })
    )],
    ["shops", (_, next) => UseState({initValue: null, next})],
    ["domRef", (_, next) => Static2({next})],
    ({domRef, countriesOverview, apis, shops}) => {
        if(domRef.get()) {
            echarts.registerMap('world', worldJson);
            let chart = echarts.init(domRef.get());
            const max = countriesOverview.value
                ? sum(countriesOverview.value.list.map(c => c.count)) / countriesOverview.value.list.length
                : 500
            ;

            let itemStyle = {
                normal:{
                    borderWidth: 0.5,
                    borderColor: 'black'
                },
                emphasis:{
                    label:{show:true}
                }
            };

            chart.setOption({
                tooltip : {
                    trigger: 'item',
                    formatter : function (params) {
                        let value = `${params.value || 0} Shops`;
                        return params.seriesName + '<br/>' + params.name + ' : ' + value;
                    }
                },
                visualMap: {
                    min: 0,
                    max,
                    inRange: {
                        color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
                    },
                    text: ['High', 'Low'],
                    calculable: true,
                    realtime: true,
                    // color: ['orangered','yellow','lightskyblue']
                },
                dataZoom: {type: 'inside', disabled: true, zoomLock: true},
                series : [
                    {
                        name: 'Top Etsy Countries',
                        type: 'map',
                        map: 'world',
                        roam: false,
                        top: 60,
                        width: '80%',
                        itemStyle: itemStyle,
                        data: countriesOverview.value?.list.map((l) => ({
                            key: l._id,
                            name: l._id.split("-").map(c1 => capitalizeFirstLetter(c1)).join(" "),
                            value: l.count
                        }))
                    }
                ]
            });

            chart.on('click',  async (params) => {
                const resp = await apis.shop.getTopShops({country: params.data.key});
                shops.onChange({list: resp, name: params.data.name});
                document.getElementById("top-shops-in-country").scrollIntoView();
            });
        }

        return <>
            <div ref={domRef.set} style={{width: `100%`, height: `500px`, margin: 0}} />

            <div id="top-shops-in-country">
                {shops.value && shops.value.name && cs(
                    keyed(shops.value.name),
                    () => (
                        <>
                            <br/>
                            <div>Top Etsy Shop in {shops.value?.name}</div>
                            <br/>
                            <Table columns={topShopsColumns} dataSource={shops.value?.list || []}/>
                        </>
                    )
                )}
            </div>
        </>
    }
);
