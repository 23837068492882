import {createFetcher} from "../../common/tools/fetcher";

export const createGuestApis = () => {
    const fetcher = createFetcher({});
    return {
        login: (params) => fetcher.post("/auth/login", params),
        register: (data) => fetcher.post("/auth/register", data)
    };
};

