import React from "react";
import {Button, Form, Input, InputNumber, Modal, Select} from "antd";
import UserAddOutlined from "@ant-design/icons/lib/icons/UserAddOutlined";

import {cs} from "../../../common/react/chain-services";
import {UseState} from "../../../common/react/use-state";
import {Static2} from "../../../common/react/static-2";
import {consumeContext} from "../../../common/react/context";
import {SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPES} from "./constants";

const { Option } = Select;

export const AddNewSubscription = ({user, reload}) => cs(
    consumeContext('apis'),
    ["visibleState", (_, next) => UseState({initValue: false, next})],
    ["static1", (_, next) => Static2({next})],
    ({visibleState, static1, apis}) => {
        const onSubmit = () => {
            static1.get().validateFields().then(async (values) => {
                await apis.users.deleteSubscription(user.subscriptions[0].id);
                await apis.users.addNewSubscription({
                    user_id: user.id,
                    plan: values.type,
                    months: values.months,
                });
                visibleState.onChange(false);
                reload();
            })
        };

        return <>
            <div>
                <Button onClick={() => visibleState.onChange(true)}>+ New Subscription</Button>
            </div>
            <Modal
                visible={visibleState.value}
                onCancel={() => visibleState.onChange(false)}
                title="Add New Subscription"
                footer={[
                    <Button key="submit" type="primary" onClick={onSubmit}>
                        Create
                    </Button>
                ]}
            >
                <Form ref={static1.set} name="control-ref" layout="vertical">
                    <Form.Item name="type" label="Subscription" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select a subscription type"
                            allowClear
                        >
                            {Object
                                .values(SUBSCRIPTION_TYPES)
                                .filter(t => [SUBSCRIPTION_TYPES.BASIC, SUBSCRIPTION_TYPES.ADVANCE].includes(t))
                                .map(t => (
                                    <Option key={t} value={t}>{t}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="months" label="Months" rules={[{ required: true }]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
);

export const UpdateSubscription = ({subscription, reload}) => cs(
    consumeContext('apis'),
    ["visibleState", (_, next) => UseState({initValue: false, next})],
    ["static1", (_, next) => Static2({next})],
    ({visibleState, static1, apis}) => {
        const onSubmit = () => {
            static1.get().validateFields().then(async (values) => {
                await apis.users.updateSubscription(subscription.id, {
                    plan: values.type,
                    status: values.status,
                    add_more_months: values.add_more_months,
                });
                visibleState.onChange(false);
                reload();
            })
        };

        const defaultFormData = {
            type: subscription.plan,
            status: subscription.status,
            add_more_months: 0
        };

        return <>
            <div>
                <Button onClick={() => visibleState.onChange(true)}>Update</Button>
            </div>
            <Modal
                visible={visibleState.value}
                onCancel={() => visibleState.onChange(false)}
                title="Update subscription"
                footer={[
                    <Button key="submit" type="primary" onClick={onSubmit}>
                        Update
                    </Button>
                ]}
            >
                <Form ref={static1.set} name="control-ref" layout="vertical" initialValues={defaultFormData}>
                    <Form.Item name="type" label="Subscription" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select a subscription type"
                            allowClear
                        >
                            {Object
                                .values(SUBSCRIPTION_TYPES)
                                .filter(t => [SUBSCRIPTION_TYPES.BASIC, SUBSCRIPTION_TYPES.ADVANCE].includes(t))
                                .map(t => (
                                    <Option key={t} value={t}>{t}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="status" label="Status" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select a status"
                            allowClear
                        >
                            {Object
                                .values(SUBSCRIPTION_STATUS)
                                .map(t => (
                                    <Option key={t} value={t}>{t}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="add_more_months" label="Months" rules={[{ required: true }]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
);
