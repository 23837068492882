const {decorateFunction} = require("./decorate-function");

export const debounce = (fn, delay) => {
    let timeout = null;
    const cancel = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };
    return decorateFunction(
        (...params) => {
            cancel();

            timeout = setTimeout(() => {
                timeout = null;
                fn(...params);
            }, delay||0);
        },
        {
            cancel,
        },
    );
};

export const nullDebounce = (fn, delay) => {
    return decorateFunction(
        (...params) => {
            fn(...params)
        },
        {
            cancel: () => {},
        },
    );
};

