import {createFetcher} from "../../common/tools/fetcher";
import isEmpty from "lodash/isEmpty";

export const createBasicApis = ({auth, onUnauthenticated}) => {
    const fetcher = createFetcher({
        onUnauthenticated,
        headers: {
            authorization: `Bearer ${auth.access_token}`,
        },
    });

    return {
        trending: {
            // getListingTrends: (filters) => fetcher.get(`/listing/trending${filters ?? ``}`),
            getListingTrends: (filters) => fetcher.get(`/listing/special-trending${filters ?? ``}`),
        },
        users: {
            getUsers: (query) => fetcher.get(`/users${query ?? ``}`),
            deleteUser: (userId) => fetcher.delete(`/user/${userId}`),
            createUsers: (data) => fetcher.post("/users", data),
            addNewSubscription: (data) => fetcher.post("/subscriptions", data),
            updateSubscription: (subscription_id, data) => fetcher.put("/subscription/" + subscription_id, data),
            deleteSubscription: (subscription_id) => fetcher.delete("/subscription/" + subscription_id),
            logout: () => fetcher.delete("/auth/logout"),
            changePassword: (user_id, data) => fetcher.post(`/user/${user_id}/change-password`, data),
            getLicense: () => fetcher.get('/license')
        },
        licenses: {
            getLicenses: () => fetcher.get("/licenses"),
            blackListLicense: (id) => fetcher.delete(`/license/${id}/blacklist`),
            updateLicense: (id, data) => fetcher.put("/license/" + id, data),
            createLicense: (data) => fetcher.post("/licenses", data),
            revokeDevice: (license_id, device_id) => fetcher.delete(`/license/${license_id}/revoke/${device_id}`)
        },
        products: {
            research: (params) => fetcher.post("/product-research", params)
        },
        shop: {
            getTopShops: (filters) => fetcher.get("/top-shops" + (!isEmpty(filters) ? `?filter=${encodeURIComponent(JSON.stringify(filters))}` : '')),
            getTopShopsAvailableFilters: () => fetcher.get("/top-shops/filters"),
            getTopCountriesOverview: () => fetcher.get("/top-countries/overview"),
            getShopRank: (name) => fetcher.get(`/shop-rank?shop_name=${name}`),
            analyzeShop: (name) => fetcher.get(`/analyze-shop?shop_name=${name}`),
        },
        followShop: {
            follow: (data) => fetcher.post("/followed-shops", data),
            getFollowedShops: () => fetcher.get("/followed-shops"),
            remove: (fs_id) => fetcher.delete(`/followed-shop/${fs_id}`),
            update: (fs_id, data) => fetcher.put(`/followed-shop/${fs_id}`, data),
        },
        keywords: {
            analyze: (keywords) => fetcher.get(`/keywords?keywords=${keywords}`)
        },
        // subscription: {
        //     getSubscriptions: () => fetcher.get(`/subscriptions`)
        // }
    }
};
