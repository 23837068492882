export const chain = (value, ...fList) => {
    for (const fn of fList) {
        if (fn) {
            value = fn(value);
        }
    }
    return value;
};

export const chain2 = (value, fList) => {
    for (const fn of fList) {
        if (fn) {
            value = fn(value);
        }
    }
    return value;
};

export const and = (...fList) => {
    return (...args) => {
        for (const fn of fList) {
            if (!fn(...args)) {
                return false;
            }
        }
        return true;
    };
};
