export const eachMatch = function(exp, str, cb) {
    let regExp = typeof exp === "string" ? new RegExp(exp, "g") : exp;
    for (let match;(match=regExp.exec(str)) != null;) {
        cb(match);
    }
};

export const countMatch = function(exp, str) {
    let count = 0;
    eachMatch(exp, str, () => {
        count++;
    });
    return count;
};

export const replaceAll = function(str, exp, replace) {
    let result = "";

    for (let i=0;;i++) {
        let m = new RegExp(exp).exec(str);
        if (m != null) {
            result += str.substring(0, m.index);
            result += replace(m, i);
            str = str.substring(m.index + m[0].length);
        } else {
            return result + str;
        }
    }
};
