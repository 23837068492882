
export const addMap = (m1, m2) => {
    let ret = {...m2};
    for (const k in m1) {
        const value = m1[k];
        if (typeof value === "object") {
            ret[k] = addMap(value, ret[k]);
        } else {
            ret[k] = value + (ret[k]||0);
        }
    }
    return ret;
};

export const randomNumber = (min, max, interval = 1) => {
    let n = Math.floor(Math.random() * ( max - min + interval) / interval);
    return n * interval + min;
};


export const quadraticEquations = (a, b, c) => {
    let discriminant = b * b - 4 * a * c;

    if (discriminant > 0) {
        let x1 = (-b + Math.sqrt(discriminant)) / (2 * a);
        let x2 = (-b - Math.sqrt(discriminant)) / (2 * a);

        return [x1, x2];
    }

    if (discriminant == 0) {
        let x = -b / (2 * a);
        return [x, x];
    }

    return []
};

export const betweenNumbers = (from, to, numb) => numb < from ? from : numb > to ? to : numb;

export const minBy = (arr, fn = v => v) => Math.min(...arr.map(fn));
export const maxBy = (arr, fn = v => v) => Math.max(...arr.map(fn));
export const randomColor = (ignoreColors = []) => {
    const random = () => {
        let color = Math.floor(Math.random()*16777215).toString(16);
        if (ignoreColors.indexOf(color) > - 1) {
            return random();
        }

        return `#${color}`;
    }

    return random();
};

export const formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") =>  {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

