export const SUBSCRIPTION_STATUS = {
    ACTIVE: 'active',
    CANCELED: 'canceled',
    TRIALING: 'trialing',
    UNPAID: 'unpaid',
    PAST_DUE: 'past_due',
};

export const SUBSCRIPTION_TYPES = {
    TRIAL: 'trial',
    BASIC: 'basic',
    ADVANCE: 'advance',
    CUSTOM: 'custom',
};
