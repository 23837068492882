import {createFetcher} from "../../common/tools/fetcher";

export const createCommonApis = () => {
    const fetcher = createFetcher({});
    return {
        fingerprint: {
            getDeviceID: (fingerprint) => createFetcher({}).post("/device", {fingerprint}),
        }
    };
};
