import {cs} from "../../../common/react/chain-services";
import { Load2 } from "../../../common/react/load2";
import { UseState } from "../../../common/react/use-state";
import "./dashboard-route.less";
import { Table } from "antd";
import { consumeContext } from "../../../common/react/context";

export const DashboardRoute = () => cs(
    consumeContext("apis"),
    ["page", (_, next) => UseState({initValue: 1, next})],
    ["data", ({apis, page}, next) => Load2({
        _key: page.value,
        fetch: () => apis.users.getUsers(`?page=${page.value}`),
        next
    })],
    ({data}) => {
        const columns = [
            {
                title: 'Email',
                render: (text, item) => item.email,
            },
            {
                title: 'Name',
                render: (text, item) => item.name,
            },
            {
                title: 'Created At',
                render: (text, item) => item.created_at,
            },
        ];
        console.log(data.value)
        return (
            <div className="dashboard-route-2iu">
                <div className="header">Dashboard</div>

                <div className="table">
                    <Table
                        columns={columns}
                        dataSource={data.value?.results || []}
                        loading={data.loading} />
                </div>
            </div>
        );
    }
)
