const data = [];

export const genTree = (item) => {
    const recur = (arr) => {
        return arr.map(item => {
            return {
                value: item.id,
                title: item.name,
                children: recur(item.children)
            }
        });
    };

    return recur(item);
};

