import { Col, Row, Image, Tag, Button, Tooltip } from "antd";
import { cs } from "../../../../../common/react/chain-services";
import format from "date-fns/format";
import {InfoCircleOutlined} from '@ant-design/icons';
import "./about.less";

const STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};
const STATUS_MAP = {
  [STATUS.ACTIVE]: {
    label: "active",
    color: "#00d97e",
  },
  [STATUS.INACTIVE]: {
    label: "inactive",
    color: "red",
  },
};

export const About = () =>
  cs(() => {
    const status = "active";
    const statusConfig = STATUS_MAP[status];
    const details = [
      {
        label: 'Shop title',
        value: 'Couples bracelets, Trending Chokers and Necklaces'
      }, {
        label: 'Accepts custom requests',
        value: 'Yes'
      }, {
        label: 'Announcement',
        value: null
      },{
        label:(
          <>
          Message to Buyers
          <Tooltip title='The message included on receipt pages and in the email buyers receive when they purchase from the shop.'>
            <span className='info-icon'>
            <InfoCircleOutlined/>
            </span>
          </Tooltip>
          </>
        ),
        value: "dsadasdasdasdsa"
      },
      {

        label:(
          <>
          Digital Message to Buyers
          <Tooltip title='If the shop sells digital items, this is the message on the Downloads page for digital orders. It applies to all digital listings purchased from the shop.'>
          <span className='info-icon'>
            <InfoCircleOutlined/>
            </span>
          </Tooltip>
          </>
        ),
        value: "dsadasdasdasdsa"
      }, 
      {
        label: 'Shop last updated',
        value: format(new Date(), "MMMM dd, yyyy"),
      }
      
    ]
    return (
      <div className="shop-analyzer-about">
        <Row justify="center">
          <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
            <Row justify="space-between">
              <Col sm={10}>
                <div className="card-box">
                  <div className="card-box-header">
                    <div className="title">Shop Owner</div>
                  </div>
                  <div className="card-box-body">
                    <div className="owner-info">
                      <div className="owner-avatar">
                        <img src="https://i.etsystatic.com/iusa/5fdfdd/40124679/iusa_75x75.40124679_hx1u.jpg?version=0" />{" "}
                      </div>
                      <div className="owner-name">Tania</div>
                      <div className="owner-description">
                        Hey. Thank you for coming to see me. Jewelry is the
                        oldest of my hobbies. (But not the only one!) Beads help
                        me to be happy. When I feel good - I'm doing a new
                        little thing. When I feel bad - I take the beads and the
                        situation is improving. I want my things bring joy to
                        people and make them a little happier...
                      </div>
                      <div className="owner-tags">
                        <Tag>Owner</Tag>
                        <Tag>Etsy Seller</Tag>
                      </div>
                    </div>
                    <div className="seller-statics">
                      <div className="metric-content">
                        <div className="title">SOLD ITEMS</div>
                        <div className="value">10.63K</div>
                      </div>
                      <div className="divider"></div>
                      <div className="metric-content">
                        <div className="title">BOUGHT ITEMS</div>
                        <div className="value">56</div>
                      </div>
                    </div>
                    <div className="card-row">
                      <div className="card-row-name">Joined</div>
                      <div className="card-row-value">
                        {format(new Date(), "dd/MM/yyyy")}
                      </div>
                    </div>
                    <div className="card-row">
                      <div className="card-row-name">Location</div>
                      <div className="card-row-value">Barcelona, Spain</div>
                    </div>
                    <div className="card-row">
                      <div className="card-row-name">Gender</div>
                      <div className="card-row-value">female</div>
                    </div>
                    <div className="info-footer">
                      <div className="status">
                        <div
                          className="circle-status"
                          style={{ color: statusConfig.color }}
                        ></div>
                        <div className="sub">{statusConfig.label}</div>
                      </div>
                      <Button type="primary">View on Etsy</Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={13}>
                <div className="card-box about">
                  <div className="card-box-header">
                    <div className="title">About</div>
                  </div>
                  <div className="card-box-body">
                    <Image
                      width={"100%"}
                      src={
                        "https://i.etsystatic.com/5204141/r/il/1802d9/1921827927/il_fullxfull.1921827927_s20h.jpg"
                      }
                    />
                    <div className="description">
                      Universal things for everyday wearing It's a shop for
                      things that are comfortable to wear every day. Universal
                      in style, they will harmoniously complement both the
                      strict office dress code and a romantic dress for an
                      evening cocktail. My shop with jewelry components and DIY
                      kits: EuropeanFindings.etsy.com
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="card-box shop-details">
              <div className="card-box-header">
                <div className="title">Shop Details</div>
              </div>
              <div className='details'>
                {details.map((each, i) => (
                  <div className='card-grid-row' key={i}>
                    <div className='card-row-label'>
                      {each.label}
                    </div>
                    <div className='card-row-value'>
                      {each.value || '-'}
                    </div>
                  </div>
                ))}
              </div>
             
            </div>
          </Col>
        </Row>
      </div>
    );
  });
