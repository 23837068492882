const {keyed} = require("./keyed");
const {fragments} = require("./fragments");
const {scope} = require("./scope");
const {cs} = require("./chain-services");
const {UseState} = require("./use-state");
const {Invoke} = require("./invoke");

export const Load2 = ({fetch, next, disabled, keepOutdatedValue, captureException, _key}) => cs(
    ["loaded", (_, next) => UseState({next})],
    ({loaded}) => {
        const loading = loaded.value == null ||  loaded.value.key !== _key;

        return fragments(
            loading && !disabled && cs(
                keyed(_key),
                () => (
                    Invoke({
                        fn: async ({isMounted}) => {
                            try {
                                const value = await fetch();
                                if (!isMounted()) {
                                    return;
                                }
                                loaded.onChange({value, key: _key});
                            } catch (error) {
                                if (captureException) {
                                    if (!isMounted()) {
                                        return;
                                    }
                                    loaded.onChange({error, key: _key});
                                } else {
                                    throw error;
                                }

                            }
                        },
                    })
                ),
            ),

            next?.({
                loading,
                ...scope(loaded, ["value"]),
                value: loading && !keepOutdatedValue ? undefined : loaded.value?.value,
                // value: loading && !keepOutdatedValue ? undefined : loaded.value.value,
                error: loading ? undefined : loaded.value?.error,
                reload: async () => {
                    const value = await fetch();
                    loaded.onChange({value, key: _key});
                },
            }),
        );
    },
);
