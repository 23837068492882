import {cs} from "../../common/react/chain-services";
import {cascadeChange} from "../../common/utils/cascade";
import {GlobalEvent} from "../../common/react/global-event";
import {Rerender} from "../../common/react/rerender";
import {createUrlFormat} from "../utils/http/url-format";

const getCurrentLocationState = ({routes1, url}) => {
    url = url ?? (() => {
        const hash = window.location.hash;
        return hash ? hash.substring(1) : null;
    })();

    const match = /^([^?]*)(\?.*)?/.exec(url);
    const path = match[1];
    const search = match[2];
    // console.log("Searching")
    for (const {urlFormat, route} of routes1) {
        const params = urlFormat.match(path);
        if (params) {
            return {props: {match: {params}, location: {search}}, route};
        }
    }
};

export const Router = ({routes, next}) => cs(
    ["routes", (_, next) => next(cascadeChange(routes, "[*].query[*]", (paramName) => paramName.endsWith("~") ? {
        paramName: paramName.substring(0, paramName.length - 1),
        carryOn: true,
    } : {
        paramName,
    }))],
    ["routes1", ({routes}, next) => next(routes.map((route) => ({
        route,
        urlFormat: createUrlFormat(route.path),
    })))],

    ["rerender", (_, next) => Rerender({next})],
    ["current", ({routes1}, next) => next(getCurrentLocationState({routes1}))],

    ({rerender}, next) => GlobalEvent({
        eventName: "hashchange",
        fn: () => rerender.invoke(),
        next,
    }),

    ({routes, current, rerender}) => next({
        routes, current,
        pushHistory: (url) => {
            if (window.history.pushState) {
                window.history.pushState(null, null, "#" + url);
            } else {
                window.location.hash = "#" + url;
            }
            rerender.invoke();
        },
    }),
);
