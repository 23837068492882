import {setPath} from "./arr-path";


function hasWildcard(nodes) {
    return !!nodes.find((n) => n.type === "wildcard");
}

function toAttr(node) {
    return (node.type === "string" ? node.value : +node.value);
}

function indices(length) {
    let r = [];
    for (let i = 0; i < length; i++) {
        r.push(i);
    }
    return r;
}

export const cascadeNodes = (object, nodes, map) => {
    let ret = [];
    cascadeNodesFind(object, nodes, (value, path, objs) => {
        ret.push(map ? map(value, path, objs) : value);
        return null;
    });
    return ret;
};

export const cascadeNodesChange = (obj, nodes, fn) => {
    let ret = obj;
    cascadeNodesFind(obj, nodes, (data, path, objs) => {

        const newData = fn(data, path, objs);
        if (newData !== data) {
            ret = setPath(ret, path, newData);
        }
    });
    return ret;
};

export const cascadeNodesFind = (object, nodes, check = v=>v) => {
    if (nodes.length === 0) {
        return check(object, [], []);
    }

    const node = nodes[0];
    const lastNodes = nodes.slice(1);

    if (node.type === "wildcard") {
        let attrs = object == null ? [] : Array.isArray(object) ? indices(object.length) : Object.keys(object);
        if (attrs.length === 0) {
            return null;
        }

        for (const attr of attrs) {
            const child = object[attr];

            const found = cascadeNodesFind(child, lastNodes, (value, path, pathObjs) => check(value, [attr].concat(path), [child].concat(pathObjs)));
            if (found) {
                return found;
            }
        }

    } else {
        const attr = toAttr(node);
        const child = object == null ? undefined : object[attr];

        if (child === undefined) {
            // return null;
            if (hasWildcard(lastNodes)) {
                return null;
            } else {
                return check(undefined, [attr].concat(lastNodes.map(toAttr)), new Array(lastNodes.length + 1).fill(undefined));
            }
        }
        return cascadeNodesFind(child, lastNodes, (value, path, objs) => check(value, [attr].concat(path), [child].concat(objs)));
    }
};
