import {cascadeNodesChange, cascadeNodes, cascadeNodesFind} from "./cascade-nodes";
import {addMap} from "./math-util";
import {parseNodes} from "./path-pattern";

export const cascade = (obj, pathStr, map) => cascadeNodes(obj, parseNodes(pathStr), map);

export const cascadeFind = (obj, pathStr, check) => cascadeNodesFind(obj, parseNodes(pathStr), check);

export const cascadeCollect = (obj, pathStr, fn = v=>v||0 ) => {
    let ret = [];
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        const found = fn(...args);
        if (found) {
            ret.push(found);
        }
    });
    return ret;
};

export const cascadeEach = (obj, pathStr, fn) => cascadeNodesFind(obj, parseNodes(pathStr), (...args) => { fn(...args) });

export const cascadeChange = (obj, pathStr, fn) => {
    return cascadeNodesChange(obj, parseNodes(pathStr), fn);
};

export const cascadeSum = (obj, pathStr, fn = v=>v||0 ) => {
    let total = 0;
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        total += fn(...args);
    });
    return total;
};

export const cascadeSumMap = (obj, pathStr, fn = v=>v||0 ) => {
    let total = {};
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        total = addMap(total, fn(...args));
    });
    return total;
};

export const cascadeCount = (obj, pathStr, fn = v=>v ) => {
    let total = 0;
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        if (fn(...args)) {
            total ++;
        }
    });
    return total;
};
