import {Component, createElement as h} from "react";

export const Rerender = ({next, getInitValue, initValue}) => h(Rerender1, {next, getInitValue, initValue});

class Rerender1 extends Component {

    render() {
        const {next} = this.props;

        return next({
            invoke: (cb) => {
                setTimeout(
                    () => this.forceUpdate(cb),
                    0,
                )
            },
        });
    }
}
