import React from "react";
import {cs} from "../../../common/react/chain-services";
import "./product-research-route.less";
import { InfoCircleOutlined, LoadingOutlined, UpCircleOutlined, DownCircleOutlined, MoreOutlined } from '@ant-design/icons';
import {Button, Col, Dropdown, Image, Input, Menu, Row, Space, Table, Tag, Tooltip, TreeSelect, } from "antd";
import {genTree} from "./getTaxonomiesTree";
import data from "./taxonomies";
import {consumeContext} from "../../../common/react/context";
import {UseState} from "../../../common/react/use-state";
import {spc} from "../../../common/react/state-path-change";
import {getPath} from "../../../common/utils/arr-path";
const { SHOW_PARENT } = TreeSelect;


const colors = ["magenta", "red", "volcano", "orange", "gold", "limegreen", "cyan", "blue", "geekblue", "purple"];

export const ProductResearchRoute = ({}) => cs(
    consumeContext("apis"),
    ["products", (_, next) => UseState({initValue: [], next})],
    ["keyword", (_, next) => UseState({initValue: "", next})],
    ["category", (_, next) => UseState({initValue: "", next})],
    ["loading", (_, next) => UseState({initValue: false, next})],
    ({keyword, category, products, loading, apis}) => {
        const search = async () => {
            loading.onChange(true);
            const _products = await apis.products.research({"keywords": keyword.value, "taxonomy_id": category.value});
            loading.onChange(false);
            products.onChange(_products.results);
        };

        const menu = (item) => (
            <Menu onClick={(e) => window.open(`https://etsy.com/${e.key}/${e.key === "listing" ? item.listing_id : item.Shop.shop_name}`, "__blank")}>
                <Menu.Item key="listing">View Product</Menu.Item>
                <Menu.Item key="shop">View Shop</Menu.Item>
            </Menu>
        );

        const columns = [
            {
                title: 'Product Name',
                dataIndex: 'product',
                key: 'product',
                render: (text, item) => <div className="product-row">
                    <Image
                        style={{borderRadius: `50%`}}
                        preview={false}
                        width={50}
                        height={50}
                        src={item.Images[0].url_570xN}
                    />
                    <div className="text">{item.title}</div>
                </div>,
                width: `30%`,
                ellipsis: true
            },
            {
                title: 'Shop Name',
                render: (text, item) => item.Shop.shop_name,
                ellipsis: true
            },
            {
                title: 'Favorites',
                dataIndex: 'num_favorers',
                key: 'num_favorers'
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                render: (text, item) => `${item.currency_code} ${item.price}`
            },
            {
                title: 'Monthly Revenue',
                dataIndex: 'month_est_revenues',
                key: 'month_est_revenues',
                render: (text) => text.toFixed(2)
            },
            {
                title: 'Monthly Sales',
                dataIndex: 'month_est_sales',
                key: 'month_est_sales',
            },
            {
                title: 'Total Sales',
                dataIndex: 'est_total_sales',
                key: 'est_total_sales',
            },
            {
                title: '',
                width: `5%`,
                render: (text, item) => <Dropdown overlay={menu(item)}>
                    <MoreOutlined />
                </Dropdown>
            }
        ];

        console.log(genTree(data));

        return (
            <div className="product-research-route-0sq">
                <div className="header">Product Research</div>

                <div className="content">
                    <div className="searcher">
                        <div className="parameters">
                            <div className="keyword">
                                <div>Which product are you researching? <Tooltip placement="top"
                                                                                 title={"Keyword for product title, product tag or leave it empty"}>
                                    <InfoCircleOutlined/>
                                </Tooltip></div>
                                <Input placeholder="Keyword"
                                       onChange={(e) => keyword.onChange(e.target.value)}
                                />
                            </div>

                            <div className="category">
                                <div>Category <Tooltip placement="top"
                                                       title={"The category or sub-category an item has on Etsy. By selecting a category only products from the category will be included in your search results. Products from unselected categories will be excluded. You can only choose one category at a time."}>
                                    <InfoCircleOutlined/>
                                </Tooltip></div>
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={genTree(data)}
                                    placeholder="Category"
                                    showCheckedStrategy={SHOW_PARENT}
                                    onChange={(value) => category.onChange(value)}
                                />
                            </div>

                            <div className="search-action">
                                <Button type="primary" loading={loading.value} onClick={search}>Search</Button>
                            </div>
                        </div>
                    </div>

                    <div className="table">
                        <Table
                            columns={columns}
                            dataSource={products.value}
                            loading={{
                                indicator: <LoadingOutlined style={{ fontSize: '30px', textAlign: 'center' }} />,
                                spinning: loading.value
                            }}
                            rowKey={record => record.listing_id}
                            expandable={{
                               expandedRowRender: record => <div className="expanded-row-9qw">
                                   <Image height={150} width={150} style={{borderRadius: 8}} src={record.Images[0].url_570xN}/>

                                   <div className="info">
                                        <div className="title">Title: <strong>{record.title}</strong></div>
                                        <div className="title">Category: <strong>{record.taxonomy_path.join("/")}</strong></div>
                                        <div className="title">Tags: {record.tags.map((item, key) => <Tag key={key} color={"blue"}>{item}</Tag>)}</div>
                                   </div>
                               </div>,
                               expandIcon: ({ expanded, onExpand, record }) => {
                                   console.log(onExpand);
                                   return expanded ? (
                                       <UpCircleOutlined onClick={e => onExpand(record, e)}/>
                                   ) : (
                                       <DownCircleOutlined onClick={e => onExpand(record, e)}/>
                                   );
                               }
                               }}
                        />

                    </div>
                </div>

            </div>
        );
    }
);

// {/*<Row gutter={[8, 8]}>*/}
// {/*    {products.value.map((product, i) => <Col span={6} key={i}>*/}
// {/*        <div className="listing-item">*/}
// {/*            <img width="100%" src={product.Images[0].url_570xN} alt=""/>*/}
// {/*            <div className="info">*/}
// {/*                <div className="title"><a href={product.url} target="_blank">{product.title}</a></div>*/}
// {/*                <div>Views: {product.views}</div>*/}
// {/*                <div>Favorites: {product.num_favorers}</div>*/}
// {/*                <div>Category: {product.taxonomy_path.join("/")}</div>*/}
// {/*            </div>*/}
// {/*        </div>*/}
// {/*    </Col>)}*/}
// {/*</Row>*/}
