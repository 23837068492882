import { cs } from "../../../common/react/chain-services";
import { Tabs, Row, Col, Form, Input, Button } from "antd";
import "./acount-route.less";
import classnames from "classnames";
import { keyed } from "../../../common/react/keyed";
import { Static2 } from "../../../common/react/static-2";
import { consumeContext } from "../../../common/react/context";
import { notificationMessage } from "../../../common/notification";

const {TabPane} = Tabs;

const ChangePasswordTab = ({}) => cs(
    consumeContext("auth"),
    consumeContext("apis"),
    ["formRef", (_, next) => Static2({next})],
    ({auth, apis, formRef}) => {
        const onFinish = async(values) => {
            await apis.users.changePassword(auth.user._id, {password: values.password});
            console.log(1);
            formRef.get().resetFields();
            notificationMessage({type: "success", description: "Successfully"});
          };
        
          const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
          };
        
          return (
            <Form
                ref={formRef.set}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="password"
                rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
              >
                <Input.Password/>
              </Form.Item>
        
              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
        
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )
          ;
    }
);


const AccountTab = ({}) => cs(
    consumeContext("auth"),
    ({auth}) => <div>
        <div>Account: <strong>{auth.user.email}</strong></div>
    </div>
);

const tabsData = [
    { title: "Account", render: AccountTab, key: "account"},
    { title: "Change Password", render: ChangePasswordTab, key: "change-password"},
];

export const AccountRoute = () => cs(
    () => <div className="account-route-8su">
        <div className="header">Account</div>

        <div className="box">
            <Tabs
                renderTabBar={(props) => {
                    const {activeKey, onTabClick} = props;
                    return (
                        <div className="custom-tab-bar">
                            <Row>
                                <Col sm={24} md={22} lg={20} xl={18} xxl={16}>
                                    {tabsData.map(tab => (
                                        <div onClick={() => onTabClick(tab.key)} key={tab.key} className={classnames('tab-navigator', {active: activeKey === tab.key})} >
                                            {tab.title}
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    );
                }}
                defaultActiveKey={"clothing"}
            >
                {tabsData.map((tab, i) => cs(
                    keyed(i),
                    () => <TabPane key={tab.key} style={{padding: '20px'}}>{tab.render?.({query: tab.key})}</TabPane>
                ))}
            </Tabs>
        </div>
    </div>
);

