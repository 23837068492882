import {UseState} from "../use-state";
import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons";
import {Image} from "antd";
import {cs} from "../chain-services";
import "./simple-carousel.less";

export const SimpleCarousel = ({images, defaultIndex, onChange, waterMark}) => cs(
    ["index", (_, next) => UseState({initValue: defaultIndex || 0, next})],
    ({index}) => {
        const current = images[index.value];
        return (
            <div className="simple-carousel">
                <div className="img-wrapper">
                    <Image width={"100%"} preview={false} src={current}/>
                    <div className="overlay">
                        <div className="content-wrapper">
                            {waterMark && <div className="watermark">{waterMark}</div>}

                            <div className="navigator">
                                    <span
                                        className="nav-btn"
                                        style={{opacity: index.value > 0 ? 1 : 0}}
                                        onClick={() => {
                                            if (index.value <= 0) {
                                                return
                                            }
                                            const nextVal = index.value - 1;
                                            onChange(nextVal);
                                            index.onChange(nextVal);
                                        }}
                                    >
                                        <LeftCircleOutlined/>
                                    </span>
                                <span
                                    className="nav-btn"
                                    style={{opacity: index.value < images.length - 1 ? 1 : 0}}
                                    onClick={() => {
                                        if (index.value >= images.length - 1) {
                                            return
                                        }
                                        const nextVal = index.value + 1;
                                        onChange(nextVal);
                                        index.onChange(nextVal);
                                    }}
                                >
                                        <RightCircleOutlined/>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
