import {ExtensionsRoute} from "../../routes/auth-routes/extensions/extensions-route";
import {ProductResearchRoute} from "../../routes/auth-routes/product-research/product-research-route";
import {FollowShopRoute} from "../../routes/auth-routes/follow-shop/follow-shop-route";
import {CalendarRoute} from "../../routes/auth-routes/calendar/calendar-route";
import {TopShopsRoute} from "../../routes/auth-routes/top-shops/top-shops-route";
import {KeywordResearchRoute} from "../../routes/auth-routes/keyword-research/keyword-research-route";
import { ShopAnalyzer } from "../../routes/auth-routes/shop-analyzer/shop-analyzer";
import {TrendingRoute} from "../../routes/auth-routes/trending/trending-route";
import { PaymentRoute } from "../../routes/auth-routes/payment/payment-route";
import { AccountRoute } from "../../routes/auth-routes/account/account-route";
import { DashboardRoute } from "../../routes/auth-routes/dashboard/dashboard-route";
import {UsersRoute} from "../../routes/auth-routes/users/users-route";

export const authRoutes = [
    {
        name: "extensions",
        path: "/extensions",
        route: {
            component: ExtensionsRoute,
        },
    },
    {
        name: "dashboard",
        path: "/dashboard",
        route: {
            component: DashboardRoute,
        },
    },
    {
        name: "trending",
        path: "/trending",
        route: {
            component: TrendingRoute,
        },
    },
    {
        name: "product-research",
        path: "/product-research",
        route: {
            component: ProductResearchRoute,
        },
    },
    {
        name: "shop-analyzer",
        path: "/shop-analyzer",
        query: ["shop_id"],
        route: {
            component: ShopAnalyzer,
        },
    },
    {
        name: "keyword-research",
        path: "/keyword-research",
        route: {
            component: KeywordResearchRoute,
        },
    },
    {
        name: "account",
        path: "/account",
        route: {
            component: AccountRoute,
        },
    },
    {
        name: "follow-shop",
        path: "/follow-shop",
        route: {
            component: FollowShopRoute,
        },
    },
    {
        name: "calendar",
        path: "/calendar",
        route: {
            component: CalendarRoute,
        },
    },
    {
        name: "top-shops",
        path: "/top-shops",
        route: {
            component: TopShopsRoute,
        },
    },
    {
        name: "payment",
        path: "/payment",
        route: {
            component: PaymentRoute,
        },
    },
    {
        name: "users",
        path: "/users",
        route: {
            component: UsersRoute,
        },
    },
    // {
    //     name: "forgot-password",
    //     path: "/forgot-password",
    //     query: ["email~", "intendedRoute~"],
    //     route: {
    //         component: ForgotPasswordRoute,
    //     },
    // },
    // {
    //     name: "reset-password",
    //     path: "/reset-password",
    //     query: ["email~", "intendedRoute~", "resetToken"],
    //     route: {
    //         component: ResetPasswordRoute,
    //     },
    // },
];

